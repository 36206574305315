import {
  Box,
  Button,
  Flex,
  Input,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { IoArrowBackOutline } from "react-icons/io5";
import { LuSendHorizonal } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../../../utils/LayoutUtils";

export const MessagingMob = () => {
  const [message, setMessage] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = React.useState([]);
  //   const [userDetail, setUserDetail] = useState({});
  const [userId, setUserId] = useState("");

  const { Id, userDetail } = location.state;
  const mobile = localStorage.getItem("mobile");
  const navigate = useNavigate();

  useEffect(() => {
    if (Id && mobile) {
      get_chat();
    }
  }, [Id, mobile]);
  const get_chat = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("type", 2);
      body.append("SM", mobile);
      body.append("RM", Id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "messaging.php",
        {
          body: body,
          method: "POST",
        }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setMessages(res.data);
      } else {
        setMessages([]);
      }
    } catch (error) {}
  };

  const send_message = async () => {
    if (message.length == 0) {
      return;
    }
    try {
      const body = new FormData();
      body.append("type", 3);
      body.append("SM", mobile);
      body.append("RM", Id);
      body.append("Message", message);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "messaging.php",
        {
          body: body,
          method: "POST",
        }
      );
      const res = await responce.json();

      if (res.success) {
        setMessage("");
        get_chat();
      } else {
      }
    } catch (error) {}
  };
  const boxRef = useRef(null);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  }, [messages]);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      height={"100%"}
    >
      {loading && messages.length == 0 ? (
        <>
          <Spacer />
          <Loader />
        </>
      ) : (
        <>
          <Flex
            bg={"brand.primary"}
            borderTop={"1px solid white"}
            py={2}
            px={3}
            alignItems={"center"}
            gap={3}
          >
            <IoArrowBackOutline
              size={25}
              color="#fff"
              cursor={"pointer"}
              onClick={() => navigate(-1)}
            />
            <FaUserCircle size={40} color="#fff" />
            <Stack spacing={0}>
              <Text fontSize={16} color={"brand.white"} fontWeight={600}>
                {userDetail.Agency_name}
              </Text>
              <Text fontSize={14} mt={0} color={"brand.white"} fontWeight={400}>
                {userDetail.Mobile_Number}
              </Text>
            </Stack>
          </Flex>
          <Box
            flexGrow={1}
            overflowY="auto"
            gap={2}
            px={3}
            ref={boxRef}
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}
          >
            {messages.map((item, index) => (
              <Flex
                key={index}
                mt={1}
                justifyContent={item.SM == mobile ? "flex-end" : "flex-start"}
              >
                <Flex
                  bg={"#F2F7FB"}
                  py={2}
                  px={4}
                  borderRadius={5}
                  alignSelf={"start"}
                  flexDirection={"column"}
                >
                  <Text fontSize={16}>{item.Message}</Text>
                  <Text fontSize={12} textAlign={"end"}>
                    {moment(item.Datetime).format("LT")}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Box>
        </>
      )}

      <Box py={2} px={3}>
        <Flex spacing={2} gap={5}>
          <Input
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            placeholder="Type your message here"
          />
          {/* <Button colorScheme="blue" variant="solid" onClick={send_message}>
            Send
          </Button> */}
          <Flex alignItems={"center"} justifyContent={"center"}>
            <LuSendHorizonal
              size={25}
              onClick={send_message}
              color="#2b3478"
              cursor={"pointer"}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
