import React, { useEffect } from "react";
import {
  DotHeading,
  HeadingMain,
  HeadingText,
} from "../../components/Compnent";
import { Container, Spacer } from "@chakra-ui/react";
import { TermsData } from "../../utils/data";
import { get_terms_and_conditions } from "../../redux/HomeSlice";
import { useSelector } from "react-redux";
import store from "../../redux/store";

export const Terms = () => {
  const { terms_and_conditions } = useSelector((state) => state.homeData);

  useEffect(() => {
    store.dispatch(get_terms_and_conditions());
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <HeadingMain title={"Terms and Conditions"} />
      {terms_and_conditions?.map((v, i) => (
        <React.Fragment key={i}>
          <DotHeading title={v?.Title} />
          <HeadingText
            pl={["22px", "24px", "26px", "28px"]}
            title={v?.Description}
          />
          <Spacer h={2} />
        </React.Fragment>
      ))}
    </Container>
  );
};
