import { Container, Flex, Grid, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import { FaCartPlus, FaEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import store from "../../../../redux/store";
import {
  get_consumable_list,
  get_member_product_list,
} from "../../../../redux/dataSlice";
import { MdDeleteForever } from "react-icons/md";
import { ProductCard } from "../../../../components/ProductCard";
import { NoData } from "../../../../components/NoData";

const MembersShopMain = () => {
  const { state } = useLocation();
  const loggedInUser = localStorage.getItem("user");
  const [heart, setHeart] = useState(true);
  const [list, setlist] = useState([]);
  const user = JSON.parse(loggedInUser);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const {
    member_consumable_list,
    member_consumable_loading,
    consumable_product_list,
    consumable_list,
    consumable_loading,
  } = useSelector((state) => state.allData);
  const obj = { sub_cat_id: state?.id, userid: user?.id };

  useEffect(() => {
    store.dispatch(get_member_product_list(obj));
    store.dispatch(get_consumable_list(obj));
  }, []);
  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={state?.name}
          titlelist={[
            { name: "Home", to: "/" },
            { name: "Let's Shop", to: -1 },
          ]}
        />
        <Flex w={["100%", "auto"]} justifyContent={"flex-end"} gap={"2"}>
          <CustomButton
            onClick={() => navigate("/let's-shop/cart", { state: state })}
            rightIcon={<FaCartPlus size={20} color="#fff" />}
            title={"Cart"}
            // onClick={() => {
            //   onOpen();
            //   //   setData({ type: "create" });
            // }}
          />
          <CustomButton
            title={"Orders"}
            bg={"brand.primary"}
            // onClick={() => {
            //   navigate("/let's-shop/suplier-order", { state: state });
            // }}
          />
        </Flex>
      </Flex>
      <Container maxW={"container.xl"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {consumable_loading ? (
          <Loader />
        ) : list?.length == 0 ? (
          <NoData />
        ) : (
          <Grid
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={[5, 5, 10]}
          >
            {list?.map((item, index) => (
              <ProductCard
                key={index}
                min_order={"50"}
                prod_name={"Naveen Test"}
                qunatity={20}
                subCat="naveen test new"
                price={200.0}
                Discount={10}
                product_cat={"Wearing Clothes"}
                heart={heart}
                setHeart={setHeart}
                count={count}
                setCount={setCount}
                add={true}
                title={"Naveen test"}
                url={
                  "https://media.istockphoto.com/id/1453308027/photo/happy-indian-student-schoolgirl-do-thumbs-up-wearing-school-uniform-holding-books-and-bag.jpg?s=612x612&w=0&k=20&c=luHqe_RQQ3OxhHA6bXSCKKOrSGnJDGqGZO0diGggOBA="
                }
              />
            ))}
          </Grid>
        )}
      </Container>
    </Container>
  );
};

export default MembersShopMain;
