import { Container, Flex, Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import BreadCrumName from "../../components/BreadCrumName";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { get_notification_list } from "../../redux/dataSlice";
import { Loader } from "../../utils/LayoutUtils";

const Notification = () => {
  const navigate = useNavigate();
  const { notification_list, notification_loading } = useSelector(
    (state) => state.allData
  );
  useEffect(() => {
    store.dispatch(get_notification_list());
  }, []);

  const update_count = async (id) => {
    try {
      const body = new FormData();
      body.append("id", id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "notification_count.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      if (res.success) {
        store.dispatch(get_notification_list());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Notifications"}
        titlelist={[{ name: "Home", to: "/" }]}
      />

      <Container maxW={"container.lg"} py={5}>
        {notification_loading ? (
          <Loader />
        ) : (
          notification_list?.map((item, index) => (
            <Stack
              key={index}
              border={"1px solid #c1c1c1"}
              borderRadius={10}
              bg={item?.status == "0" ? "#f2f3f5" : ""}
              py={2}
              px={5}
              mt={[3, 5]}
              cursor={"pointer"}
              onClick={() => {
                navigate("/notification-detail", { state: item });
                update_count(item?.ID);
              }}
            >
              <Text
                color={"#2b3478"}
                fontSize={[16, 18, 20]}
                fontWeight={item?.status == "1" ? 500 : 800}
              >
                {item.title}
              </Text>
              <Flex
                alignItems={["flex-start", "center"]}
                flexDirection={["column", "row"]}
                gap={[2, 0]}
              >
                <Text
                  color={"brand.text1"}
                  flex={1}
                  fontSize={[14, 15, 16]}
                  fontWeight={500}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  css={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.message}
                </Text>

                <Text
                  w={"100%"}
                  textAlign={"right"}
                  color={"brand.text1"}
                  fontSize={[14, 15, 16]}
                  fontWeight={500}
                >
                  {item.datetime}
                </Text>
              </Flex>
            </Stack>
          ))
        )}
      </Container>
    </Container>
  );
};

export default Notification;
