import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get_profile } from "../../../redux/dataSlice";
import store from "../../../redux/store";
import { Center, Flex, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { CustomButton, InputBox, Loader } from "../../../utils/LayoutUtils";
import ShowData from "../../../components/ShowData";
import CustomModal from "../../../components/CustomModal";
import { IoMdDownload } from "react-icons/io";
import { validateEmail2 } from "../../../utils/utils";
import { toast } from "react-toastify";
import { NoData } from "../../../components/NoData";

const PartnerProfile = ({ title }) => {
  const { profile_data, profile_loading } = useSelector(
    (state) => state.allData
  );
  const { win_width } = useSelector((state) => state.otherData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editItem, setEditItem] = useState({});
  const [loading, setloading] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const mobile = localStorage.getItem("mobile");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const body = new FormData();
    body.append("Mobile_Number", mobile);
    body.append("type", 3);
    store.dispatch(get_profile({body:body}));
  };
  console.log("profile_data ===", profile_data);

  const data = profile_data[0];

  const update_profile = async () => {
    if (
      !editItem?.Agency_name ||
      !editItem?.DOC ||
      !editItem?.Address ||
      (editItem?.Address && editItem?.Address?.length < 25) ||
      (editItem?.DOC && editItem?.DOC?.length != 10) ||
      !editItem?.OMC_Type ||
      !editItem?.Email_Address ||
      !validateEmail2(editItem?.Email_Address)
    ) {
      setEditItem({
        ...editItem,
        Agency_nameMess: !editItem?.Agency_name
          ? "Agency Name is required"
          : "",
        DOCMess: !editItem?.DOCMess
          ? "DOC Date is required"
          : editItem?.DOC?.length != 10
          ? "Please Enter Valid DOC Date"
          : "",
        emailMess: !editItem?.email
          ? "Email is required"
          : !validateEmail2(editItem?.email)
          ? "Email is invalid"
          : "",
        OMC_TypeMess: !editItem?.OMC_Type ? "OMC Type is required" : "",
        AddressMess: !editItem?.OMC_Type ? "OMC Type is required" : "",
      });
      return;
    }

    try {
      setloading(true);
      const body = new FormData();

      body.append("userid", user?.id);
      body.append("Agency_name", editItem?.Agency_name);
      body.append("DOCMess", editItem?.DOCMess);
      body.append("email", editItem?.email);
      body.append("OMC_Type", editItem?.OMC_Type);
      body.append("Address", editItem?.Address);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "profile.php",
        {
          method: "POST",
          body: body,
        }
      );
      const res = await responce.json();
      setloading(false);

      if (res.success) {
        toast.success(res.message);
        setEditItem({});
        fetchData();
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Flex gap={5} alignItems={"center"} justifyContent={"space-between"}>
        <Text fontSize={18} color={"#2b3478"} fontWeight={600}>
          {title}
        </Text>
        {profile_data?.length > 0 && (
          <CustomButton title={"Edit"} onClick={onOpen} />
        )}
      </Flex>
      {profile_loading ? (
        <Loader />
      ) : profile_data?.length == 0 ? (
        <NoData />
      ) : (
        <Stack>
          <Flex
            gap={5}
            mt={2}
            flexDirection={["column", "column", "row", "row"]}
          >
            <ShowData title={"Agency ID"} value={data?.Agency_id} flex={1} />
            <ShowData title={"Name"} value={data?.Name} flex={1} />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"DOB"} value={data?.DOB} flex={1} />
            <ShowData title={"DOA"} value={data?.DOA} flex={1} />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData
              title={"Mobile Number"}
              value={data?.Mobile_Number}
              flex={1}
            />
            <ShowData
              title={"Partner Status"}
              value={data?.Partner_Status}
              flex={1}
            />
          </Flex>

          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"Address"} value={data?.Address} flex={1} />
            <ShowData
              title={"Email Address"}
              value={data?.Email_add}
              flex={1}
            />
          </Flex>

          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"DOJ"} value={data?.DOJ} flex={1} />
            <ShowData title={"DOE"} value={data?.DOE} flex={1} />
          </Flex>
          {/* <Flex
            gap={5}
            flexDirection={["row", "row", "row"]}
            alignItems={"center"}
            mt={5}
          >
            <Text>Identity Card</Text>
            <CustomButton
              title={
                win_width < 500 ? (
                  <IoMdDownload color="#fff" size={22} cursor={"pointer"} />
                ) : (
                  "Download"
                )
              }
            />
          </Flex> */}
        </Stack>
      )}
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        title={"Update Partner Profile"}
      >
        <Flex gap={[0, 5]} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            value={editItem?.Email_Address}
            onChange={(v) => {
              setEditItem({
                ...editItem,
                Email_Address: v.target.value,
                Email_AddressMess: "",
              });
            }}
            errorMess={editItem?.Email_AddressMess}
            placeholder={"Agency Name"}
            label={"Agency Name"}
          />
          <InputBox
            value={editItem?.Email_Address}
            onChange={(v) => {
              setEditItem({
                ...editItem,
                Email_Address: v.target.value,
                Email_AddressMess: "",
              });
            }}
            errorMess={editItem?.Email_AddressMess}
            placeholder={"Email Address"}
            label={"Email Address"}
          />
        </Flex>
        <Flex gap={[0, 5]} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            value={editItem?.Email_Address}
            onChange={(v) => {
              setEditItem({
                ...editItem,
                Email_Address: v.target.value,
                Email_AddressMess: "",
              });
            }}
            errorMess={editItem?.Email_AddressMess}
            placeholder={"Address"}
            label={"Address"}
          />
          <Flex flex={1} />
        </Flex>

        <Center mt={5} mb={5}>
          <CustomButton title={"Update"} />
        </Center>
      </CustomModal>
    </>
  );
};

export default PartnerProfile;
