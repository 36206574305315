import {
  Card,
  Container,
  Image,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { HeadingMain } from "../../components/Compnent";
import { InitiativesData } from "../../utils/data";

const Initiatives = ({ Ref, data, img }) => {
  return (
    <>
      <HeadingMain title={"Initiatives"} ref={Ref} />
      {data?.map((v, i) => (
        <Text
          color={"#000"}
          fontSize={16}
          fontWeight={400}
          mb={3}
          textAlign={"justify"}
          key={i}
        >
          {v?.Description}
        </Text>
      ))}
      <Spacer h={7} />
      <SimpleGrid columns={[1, 2, 3]} spacing={5}>
        {img?.map((item, index) => (
          <Card bg="#fff" key={index}>
            <Image
              borderRadius={".5rem"}
              src={item?.Images}
              h={"300"}
              w={"100%"}
              objectFit={"fill"}
              alignSelf={"center"}
            />
            <Text padding={".65rem"} fontWeight={600} textAlign={"center"}>
              {item?.Title}
            </Text>
          </Card>
        ))}
      </SimpleGrid>
    </>
  );
};

export default Initiatives;
