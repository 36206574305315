import { Container, Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { get_about_us, get_gallery } from "../../redux/dataSlice";
import store from "../../redux/store";
import BreadCrumName from "../../components/BreadCrumName";
import { Loader } from "../../utils/LayoutUtils";

const AboutUs = () => {
  const { state } = useLocation();
  const { about_data, about_loading } = useSelector((state) => state.allData);
  const navigate = useNavigate();
  useEffect(() => {
    store.dispatch(get_about_us());
  }, []);
  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"About Us"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.xl"} p={4}>
        {about_loading && about_data?.length == 0 ? (
          <Loader />
        ) : (
          about_data?.map((item, index) => (
            <Stack marginTop={5} key={index}>
              <Text color={"brand.text"} fontSize={18} fontWeight={600}>
                {item.Title}
              </Text>
              <Text color={"brand.black"} fontSize={16} fontWeight={400} pt={0}>
                {item.Description}
              </Text>
            </Stack>
          ))
        )}
      </Container>
    </Container>
  );
};

export default AboutUs;
