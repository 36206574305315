import { Container, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import BreadCrumName from "../../components/BreadCrumName";

const Orders = () => {
  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Orders"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.lg"} px={[0,5]}>
        {Array(10)
          .fill()
          .map((item, index) => (
            <Stack
              key={index}
              border={"1px solid #c1c1c1"}
              borderRadius={10}
              py={2}
              px={[3,5]}
              mt={5}
              cursor={"pointer"}
            >
              <Flex alignItems={"center"} pt={[2,0]} pb={[2,0]}>
                <Text
                  color={"brand.black"}
                  flex={1}
                  fontSize={[16,18,20]}
                  fontWeight={500}
                >
                  01822127838
                </Text>
                <Text color={"brand.black"} fontSize={[16,18,20]} fontWeight={500}>
                  {"23-02-2024 (05:34 PM)"}
                </Text>
              </Flex>
              <Flex alignItems={"center"}>
                <Text
                  color={"brand.black"}
                  flex={1}
                  fontSize={[16,18,20]}
                  fontWeight={500}
                >
                  Amount: ₹ 260.00
                </Text>
                <Text color={"brand.black"} fontSize={[16,18,20]} fontWeight={500}>
                  Placed
                </Text>
              </Flex>
            </Stack>
          ))}
      </Container>
    </Container>
  );
};

export default Orders;
