import { Container, Flex, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import BreadCrumName from "../../components/BreadCrumName";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { Loader } from "../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import ShowMoreText from "../../../../components/Compnent";

const Services = () => {
  const navigate = useNavigate();
  const { notification_list, notification_loading } = useSelector(
    (state) => state.allData
  );
  const { type, services } = useSelector((state) => state.otherData);

  
  const [loading, setLoading] = useState(false);
  const [reqServicesList, setReqServicesList] = useState([
    {
      ID: 1,
      des: "lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa ",
      user_name: "Naveen Patidar",
      date_time: "12/12/2024 06:12 PM",
      type: "Approved",
      status: "On Working",
    },
    {
      ID: 2,
      des: "lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa ",
      user_name: "Kapil Patidar",
      date_time: "12/12/2024 06:12 PM",
      type: "Rejected",
      status: "",
      reason: "Rejected by Admin due to non availability",
    },
  ]);

  const update_count = async (id) => {};

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Services"}
        titlelist={[
          { name: "Home", to: "/" },
          type == 1&&{ name: "Our Offering", to: "/home" },
          { name: "Get Advice", to: "/get-advice" },
          { name: "Add-Advice", to: -1 },
        ]}
      />

      <Container maxW={"container.lg"} py={5}>
        {loading ? (
          <Loader />
        ) : (
          reqServicesList?.map((item, index) => (
            <Stack
              key={index}
              border={"1px solid #c1c1c1"}
              borderRadius={10}
              bg={item?.status == "0" ? "#f2f3f5" : ""}
              py={2}
              px={5}
              mt={[3, 5]}
              cursor={"pointer"}
              onClick={() => {
                // navigate("/notification-detail", { state: item });
                // update_count(item?.ID);
              }}
            >
              <Flex justify={"space-between"}>
                <Text
                  color={"#2b3478"}
                  fontSize={[16, 18, 20]}
                  fontWeight={item?.status == "1" ? 500 : 800}
                >
                  {item?.user_name}
                </Text>
                <Text
                  display={"flex"}
                  color={
                    item?.type == "Approved" ? "brand.primary" : "brand.btn"
                  }
                  gap={4}
                >
                  {item?.type}
                </Text>
              </Flex>

              <Text color={"brand.text1"}>
                {/* <ShowMoreText text={item.des} maxLength={40} /> */}
                {item.des}
              </Text>

              <Flex
                alignItems={["flex-start", "center"]}
                flexDirection={["column", "row"]}
                gap={[2, 0]}
              >
                {item?.status && (
                  <Text
                    w={"fit-content"}
                    display={"flex"}
                    flex={1}
                    color={"brand.primary"}
                    gap={4}
                  >
                    {item?.status}
                  </Text>
                )}
                {item?.reason && (
                  <Text
                    w={"fit-content"}
                    display={"flex"}
                    flex={1}
                    color={"brand.btn"}
                    gap={4}
                  >
                    {item?.reason}
                  </Text>
                )}

                <Text
                  w={"100%"}
                  flex={1}
                  textAlign={"right"}
                  color={"brand.text1"}
                  fontSize={[14, 15, 16]}
                  fontWeight={500}
                >
                  {item.date_time}
                </Text>
              </Flex>
            </Stack>
          ))
        )}
      </Container>
    </Container>
  );
};

export default Services;
