import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import dataSlice from "./dataSlice";
import otherSlice from "./otherSlice";
import homeSlice from "./HomeSlice";

const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  allData: dataSlice,
  otherData: otherSlice,
  homeData: homeSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;
export const persistor = persistStore(store);
