import { Text } from '@chakra-ui/react'
import React from 'react'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { MdOutlinePendingActions } from 'react-icons/md'
import { RxCrossCircled } from 'react-icons/rx'

const StatusComp = ({status,right=2,top=2}) => {
  return (
    <>
      {/* {status != "0" ? (
                <Text position={"absolute"} right={right} top={top}>
                  {status == "1" ? (
                    <IoCheckmarkCircle size={35} color={"green"} />
                  ) : status == "2" ? (
                    <RxCrossCircled size={35} color={"red"} />
                  ) : (
                    ""
                  )}
                </Text>
              ) : (
                ""
              )} */}
                <Text position={"absolute"} right={right} top={top}>
                {status != "0" ? (
                  <>
                    {status == "1" ? (
                      <IoCheckmarkCircle size={35} color={"green"} />
                    ) : status == "2" ? (
                      <RxCrossCircled size={35} color={"red"} />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <MdOutlinePendingActions size={25} color={"orange"} />
                )}
              </Text>
    </>
  )
}

export default StatusComp