import {
  Box,
  Container,
  Flex,
  Grid,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../redux/store";
import { get_category } from "../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import { Loader } from "../../../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import CustomPopup from "../../../../components/CustomPopup";
import ListItem from "../../../../components/ListItem";

const AssociationInfo = () => {
  const { state } = useLocation();
  const { categoty_data, cat_loading } = useSelector((state) => state.allData);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const body = new FormData();
    body.append("action", "Details");
    body.append("id", 1);
    store.dispatch(get_category(body));
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Association Info"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.xl"} mt={[5,5]} p={[1, 2, 3, 4]}>
        {cat_loading ? (
          <Loader />
        ) : categoty_data?.length == 0 ? (
          <Text>Working</Text>
        ) : (
          <Grid
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={[5, 5, 10]}
          >
            {categoty_data?.map((item, index) => (
              <ListItem
                key={index}
                title={item.name}
                url={item.Web_Images}
                onClick={() => {
                  index == 2
                    ? onOpen()
                    : index == 0
                    ? navigate(`/association-info/state-office-bearers`, {
                        state: { name: item.name, id: item.id },
                      })
                    : navigate(`/association-info/district-office-bearers`, {
                        state: { name: item.name, id: item.id },
                      });
                }}
              />
            ))}
          </Grid>
        )}
      </Container>
      <CustomPopup
        isOpen={isOpen}
      fontSize={true}
        onClose={onClose}
        // title={"Oops"}
        mess={"Coming soon..."}
        single_button={true}
        b_color={"#2b3478"}
        b_name={"ok"}
      />
    </Container>
  );
};

export default AssociationInfo;
