import { Container } from "@chakra-ui/react";
import React, { useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useLocation } from "react-router-dom";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { FaHeart, FaIndianRupeeSign } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { CartCard } from "../../../../components/CartCard";
import { CustomButton } from "../../../../utils/LayoutUtils";

const ShopCart = () => {
  const [heart, setHeart] = useState(true);
  const [list, setList] = useState([1, 2, 3]);
  const { state } = useLocation();
  return (
    <Container maxW={"container.xl"} py={2}>
      <BreadCrumName
        current={"Cart"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Let's Shop", to: -2 },
          { name: state.name, to: -1 },
        ]}
      />

      <Container maxW={"container.xl"} py={[0,2]}>
        <div className="shopping-cart">
          {/* <!-- Title --> */}
          <div className="title">Cart...</div>

          {list?.map((v, i) => (
            <CartCard key={i} />
          ))}

          {/* <!-- Product #1 --> */}
          {/* <div class="item">
            <div class="buttons">
                <RxCross2 size={20} />
                <FaHeart
                  onClick={() => setHeart(!heart)}
                  cursor="pointer"
                  color={heart ? "red" : "gray"}
                  size={20}
                />
            </div>

            <div class="image">
              <img src={img} alt="" />
            </div>

            <div class="description">
              <span>Common Projects</span>
              <span>Bball High</span>
              <span>White</span>
            </div>

            <div class="quantity">
              <button class="plus-btn" type="button" name="button">
              <CiSquareMinus size={24} />
              </button>
              <input type="text" name="name" value="1" />
              <button class="minus-btn" type="button" name="button">
                <CiSquarePlus size={24} />
              </button>
            </div>

            <div class="total-price"><FaIndianRupeeSign />
            549</div>
          </div> */}

          {/* <!-- Product #2 --> */}
          {/* <div class="item">
            <div class="buttons">
              <span class="delete-btn">
                <RxCross2 size={20} />
              </span>
              <span class="like-btn">
                <FaHeart
                  onClick={() => setHeart(!heart)}
                  cursor="pointer"
                  color={heart ? "red" : "gray"}
                  size={20}
                />
              </span>
            </div>

            <div class="image">
              <img src={img} alt="" />
            </div>

            <div class="description">
              <span>Maison Margiela</span>
              <span>Future Sneakers</span>
              <span>White</span>
            </div>

            <div class="quantity">
              <button class="plus-btn" type="button" name="button">
                <CiSquarePlus size={24} />
              </button>
              <input type="text" name="name" value="1" />
              <button class="minus-btn" type="button" name="button">
                <CiSquareMinus size={24} />
              </button>
            </div>

            <div class="total-price">$870</div>
          </div> */}

          {/* <!-- Product #3 --> */}
          {/* <div class="item">
            <div class="buttons">
              <span class="delete-btn">
                <RxCross2 size={20} />
              </span>
              <span class="like-btn">
                <FaHeart
                  onClick={() => setHeart(!heart)}
                  cursor="pointer"
                  color={heart ? "red" : "gray"}
                  size={20}
                />
              </span>
            </div>

            <div class="image">
              <img src={img} alt="" />
            </div>

            <div class="description">
              <span>Our Legacy</span>
              <span>Brushed Scarf</span>
              <span>Brown</span>
            </div>

            <div class="quantity">
              <button class="plus-btn" type="button" name="button">
                <CiSquarePlus size={24} />
              </button>
              <input type="text" name="name" value="1" />
              <button class="minus-btn" type="button" name="button">
                <CiSquareMinus size={24} />
              </button>
            </div>

            <div class="total-price">$349</div>
          </div> */}

          <div className="totals">
            <div className="totals-item">
              <label>Subtotal</label>
              <div className="totals-value" id="cart-subtotal">
                71.97
              </div>
            </div>
            <div className="totals-item">
              <label>Tax (5%)</label>
              <div className="totals-value" id="cart-tax">
                3.60
              </div>
            </div>
            <div className="totals-item">
              <label>Shipping</label>
              <div className="totals-value" id="cart-shipping">
                15.00
              </div>
            </div>
            <div className="totals-item totals-item-total">
              <label>Grand Total</label>
              <div className="totals-value" id="cart-total">
                90.57
              </div>
            </div>
          </div>
         <div className="checkout">
         <CustomButton title={"Check Out"} />
         {/* Check-Out */}
         </div>
        </div>

        
      </Container>
    </Container>
  );
};

export default ShopCart;
