import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

function BreadCrumName({ titlelist = [], to, current }) {
  return (
    <Breadcrumb color="#2b3478">
      {titlelist.map((v, i) => (
        v &&
        <BreadcrumbItem key={i}>
          <Link to={v.to}>
            <Text
              fontSize={[15, "md", "lg"]}
              fontWeight="large"
              color="#2b3478"
              mb={0}
            >
              {v.name}
            </Text>
          </Link>
        </BreadcrumbItem>
      ))}
      {current && (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            color={"#2b3478"}
            href={to}
            fontSize={[15, "md", "lg"]}
          >
            {current}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}

export default BreadCrumName;
