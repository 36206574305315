import React from "react";
import {
  Box,
  Flex,
  Stack,
  Link,
  Container,
  useDisclosure,
  calc,
  VStack,
  Spacer,
} from "@chakra-ui/react";
import Header from "./Header";
import { Outlet } from "react-router-dom";

const Layout = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <VStack
      h={"100vh"}
      minH={"100vh"}
      maxW={"full"}
      spacing={0}
      minW={"100wh"}
      overflowY={"auto"}
      m={0}
      p={0}
    >
      <Stack
        h={70}
        position={"sticky"}
        // bg="#2b3478"
        bg={"#2b3478"}
        w={"full"}
        justify={"center"}
        py={2}
        spacing={0}
      >
        <Header />
      </Stack>
      <Stack
        overflowY={"auto"}
        w={"full"}
        bg={"#f8f9fe"}
        h={"full"}
        maxW={"full"}
        spacing={0}
      >
        <Outlet />
        <Spacer />
      </Stack>
    </VStack>
  );
};

export default Layout;
