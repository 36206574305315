export const onLogin = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "singin.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const onRegister = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "New_member.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
export const onRegister1 = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "New_Supplier.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
export const onSendOTP = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "otp_request.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const onForgotOTP = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "forgot_otp.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
export const onResetPassword = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "forget.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
export const contact_us_api = async (body) => {
  try {
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Contact_us.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};


export const certificate_download_api = async (body) => {
  try {
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "certificate.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};


