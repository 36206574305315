import { Checkbox, Container, Editable, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import {
  CustomButton,
  InputBox,
  SelectBox,
  SelectBox2,
  SelectImage,
} from "../../../../../utils/LayoutUtils";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomPopup from "../../../../../components/CustomPopup";

const AdviceMain = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [Show, setShow] = useState(false);
  const [selcted, setSelcted] = useState("1");
  const [checked, setChecked] = useState(false);
  const [fileData, setFileData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { type } = useSelector((state) => state.otherData);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (state.subCat_id) {
      setLoading(true);
      const body = new FormData();
      body.append("action", "listsupplier");
      body.append("category_id", 4);
      body.append("sub_category_id", state?.subCat_id);
      body.append("service_provider", user?.id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      console.log("res ===", res);
      setLoading(false);
      if (res.success) {
        setShow(true);
        // setFileData(res?.data[0]?.certificates);
        setData({
          type: res?.data[0]?.Sub_Category_id,
          description: res?.data[0]?.Description,
          charges: res?.data[0]?.Charges,
          // photo: { name: res?.data[0]?.Document },
        });
      }
    }
  };

  const update_items = async () => {
    if (
      (!state?.subCat_id &&
        (!data.type || (data.type && data.type?.length == 0))) ||
      !data.charges ||
      (data.charges && data.charges?.length == 0) ||
      !data.description ||
      (data.description && data.description?.length == 0) 
      // !fileData[0]?.name ||
      // !data?.photo?.name
    ) {
      setData({
        ...data,
        typeMess:
          !state?.subCat_id &&
          (!data.type || (data.type && data.type?.length == 0))
            ? "Type is required"
            : "",
        chargesMess:
          !data.charges || (data.charges && data.charges?.length == 0)
            ? "Charges is required"
            : "",
        descriptionMess:
          !data.description ||
          (data.description && data.description?.length == 0)
            ? "Description is required"
            : "",
        // fileMess: !fileData[0]?.name ? "Certificate is required" : "",
        // photoMess: !data?.photo?.name ? "Photo is required" : "",
      });
      return;
    }

    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "update");
      body.append("category_id", 4);
      body.append("sub_category_id", state?.subCat_id || data?.type);
      body.append("service_provider", user?.id);
      // body.append("title",4);
      body.append("charges", data?.charges);
      body.append("description", data?.description);
      body.append("image", data.photo || "");
      fileData?.forEach((file) => {
        body.append("certificates[]", file);
      });

      // update(category_id,sub_category_id,title,charges,description,service_provider,id,certificates[],cert_id[],image,)
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        fetchData();
        navigate(-1);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error =====", error);
    }
  };
  const delete_items = async () => {
    // delete(id)
    try {
      const body = new FormData();
      body.append("action", "delete");
      body.append("category_id", 4);
      body.append("sub_category_id", state?.subCat_id || data?.type);
      body.append("service_provider", user?.id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setData({})
        navigate(-1);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      
    }
  };

  // update(category_id,sub_category_id,title,charges,description,service_provider,id,certificates[],cert_id[],image,)

  const add_items = async () => {
    if (
      (!state?.subCat_id &&
        (!data.type || (data.type && data.type?.length == 0))) ||
      !data.charges ||
      (data.charges && data.charges?.length == 0) ||
      !data.description ||
      (data.description && data.description?.length == 0) ||
      !fileData[0]?.name ||
      !data?.photo?.name
    ) {
      setData({
        ...data,
        typeMess:
          !state?.subCat_id &&
          (!data.type || (data.type && data.type?.length == 0))
            ? "Type is required"
            : "",
        chargesMess:
          !data.charges || (data.charges && data.charges?.length == 0)
            ? "Charges is required"
            : "",
        descriptionMess:
          !data.description ||
          (data.description && data.description?.length == 0)
            ? "Description is required"
            : "",
        fileMess: !fileData[0]?.name ? "Certificate is required" : "",
        photoMess: !data?.photo?.name ? "Photo is required" : "",
      });
      return;
    }
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "create");
      body.append("category_id", 4);
      body.append("sub_category_id", state?.subCat_id || data?.type);
      body.append("service_provider", user?.id);
      // body.append("title",4);
      body.append("charges", data?.charges);
      body.append("description", data?.description);
      body.append("image", data.photo);
      fileData?.forEach((file) => {
        body.append("certificates[]", file);
      });
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setData({});
        navigate(-1);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleFileChange = (e, id) => {
    const files = Array.from(e.target.files);
    setFileData(
      fileData.map((item) =>
        item.id === id ? { ...item, file: files, fileMess: "" } : item
      )
    );
  };
  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={state.name}
          titlelist={[
            { name: "Home", to: "/" },
            type == 1 && { name: "Our Offering", to: "/home" },
            { name: "Get Advice", to: "/get-advice" },
          ]}
        />
        <Flex w={["100%", "auto"]} justifyContent={"flex-end"} gap={"2"}>
          <CustomButton
            bg={"brand.btn"}
            title={"Notification"}
            onClick={() => {
              navigate("/get-advice/requested-services", {
                state: state,
              });
            }}
          />
         {Show &&
         
         <CustomButton
         bg={"brand.primary"}
         title={!edit ? "Edit" : "Cancel"}
         onClick={() => {
           // if (edit) {
           //   setEdit(false);
           // } else if (!edit) {
           //   setEdit(true);
           // }
           setEdit(!edit)
         }}
       />}
          <CustomButton
            bg={"brand.btn"}
            title={"Delete"}
            onClick={onOpen}
          />
        </Flex>
      </Flex>
      <Container
        maxW={"container.sm"}
        display={"flex"}
        flexDir={"column"}
        gap={3}
        p={4}
      >
        <SelectBox
          label2={"Policy"}
          value={data?.type || state.subCat_id}
          disable={state.subCat_id || (Show && !edit)}
          options={
            <>
              <option value={""}>Select Type </option>
              <option value={"6"}>OMC Show cause notice </option>
              <option value={"4"}>Gst</option>
              <option value={"5"}>Income tax</option>
              <option value={"10"}>Arbitration</option>
              <option value={"11"}>Court Cases</option>
              <option value={"7"}>Reconstitution</option>
              <option value={"8"}>Civil Supply Notices</option>
              <option value={"9"}>Appels</option>
            </>
          }
          onChange={(e) => {
            setData({
              ...data,
              type: e.target.value,
            });
          }}
          errorMess={data?.typeMess}
        />
        <InputBox
          disable={(Show && !edit)}
          placeholder={"Charges"}
          value={data.charges}
          onChange={(v) => {
            const inputValue = v.target.value;
            if (
              (inputValue?.length <= 15 &&
                /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(inputValue)) ||
              inputValue == 0 ||
              !data.charges
            ) {
              setData({
                ...data,
                charges: /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(inputValue)
                  ? inputValue
                  : "",
                chargesMess: "",
              });
            }
          }}
          errorMess={data.chargesMess}
        />
        <InputBox
          disable={(Show && !edit)}
          area={true}
          placeholder={"Description"}
          value={data.description}
          onChange={(v) => {
            const input = v.target.value.replace(/\s\s+/g, " ");
            setData({
              ...data,
              description: input,
              descriptionMess: "",
            });
          }}
          errorMess={data.descriptionMess}
        />
        {/* <Flex
          w={"full"}
          display={"flex"}
          alignItems={"center"}
          h={"full"}
          justifyContent={"center"}
        > */}
        <SelectImage
          disable={(Show && !edit)}
          multiple={true}
          value={ fileData?.length > 0
            ? fileData?.map((v) =>
                v?.name ? `${v?.name},` : `${v?.image_url},`
              )
            : "Certificates"}
          filename={
            fileData?.length > 0
              ? fileData?.map((v) =>
                  v?.name ? `${v?.name},` : `${v?.image_url},`
                )
              : "Certificates"
          }
          onChange={(event) => {
            const selectedFiles = Array.from(event.target.files);
            setFileData(selectedFiles);
            setData({...data,fileMess:""})
          }}
          errorMess={data.fileMess}
        />
        {/* <CustomButton
            mt={2}
            bg={"brand.primary"}
            title={"ADD"}
            onClick={() => {
              const newData = {
                id: fileData.length + 1,
              };
              setFileData([...fileData, newData]);
            }}
          /> */}
        {/* </Flex> */}
        <SelectImage
          disable={(Show && !edit)}
          // label={"Upload Document"}
          value={data.photo?.name}
          filename={data.photo?.name || "Upload photo"}
          onChange={(v) => {
            setData({ ...data, photo: v.target.files[0], photoMess: "" });
          }}
          errorMess={data.photoMess}
        />
        {Show ? (
          <>
            {edit ? (
              <CustomButton
                title={"Update"}
                mt={5}
                loading={loading}
                onClick={update_items}
              />
            ) : (""
             
            )}
          </>
        ): <>
        <Text
          fontSize={14}
          fontWeight={500}
          color={"brand.primary"}
          display={"flex"}
          gap={"1rem"}
          justifyContent={"flex-start"}
          alignItems={["flex-start", "center"]}
          mt={2}
        >
          <Checkbox
            borderColor={"gray"}
            mt={[1, 0]}
            isChecked={checked}
            defaultChecked={checked}
            onChange={(e) => {
              setChecked(!checked);
            }}
            colorScheme={"blue"}
          />
          <Text>
            By clicking Submit, you agree to our{" "}
            <Link
              to="/terms"
              textDecoration={"underline"}
              style={{ textDecoration: "underline" }}
            >
              Terms & Conditions
            </Link>
          </Text>
        </Text>
        <CustomButton
          title={"Submit"}
          mt={5}
          disabled={!checked}
          loading={loading}
          onClick={add_items}
        />
      </>}
      </Container>
      <CustomPopup
        isOpen={isOpen}
        onClick={delete_items}
        b_name={"Delete"}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? You want to Delete."}
      />
    </Container>
  );
};

export default AdviceMain;
