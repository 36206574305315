import { Center, Container, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CustomButton, InputBox } from "../utils/LayoutUtils";
import { validateEmail2, validateMobile } from "../utils/utils";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import store from "../redux/store";
import { get_event_details } from "../redux/otherSlice";
import { useSelector } from "react-redux";
import moment from "moment";

const EventManagement = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [qr_code, setQr_code] = useState("");

  const { event_details } = useSelector((state) => state.otherData);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      store.dispatch(get_event_details(id));
    }
  }, [id]);

  const onAdd = async () => {
    if (
      !data?.Name ||
      data?.Name?.trim()?.length == 0 ||
      !data?.c_name ||
      data?.c_name?.trim()?.length == 0 ||
      !data?.email ||
      !data?.mobile ||
      !validateEmail2(data?.email) ||
      !validateMobile(data?.mobile)
    ) {
      setData({
        ...data,
        NameMess:
          !data?.Name || data?.Name?.trim()?.length == 0
            ? "Name is required"
            : "",
        c_nameMess:
          !data?.c_name || data?.c_name?.trim()?.length == 0
            ? "Company Name is required"
            : "",
        // designationMess: !data?.designation ? "Designation is required" : "",
        emailMess: !data?.email
          ? "Email is required"
          : !validateEmail2(data?.email)
          ? "Email is invalid"
          : "",
        mobileMess: !data?.mobile
          ? "Mobile is required"
          : !validateMobile(data?.mobile)
          ? "Please Enter valid mobile no."
          : "",
      });
      return;
    }
    try {
      setLoading(true);
      // event_attendance(event_id,type,name,company_name,email,mobile,designation)

      const body = new FormData();
      body.append("action", "event_attendance");
      body.append("event_id", id);
      body.append("type", "visitor");
      body.append("name", data?.Name);
      body.append("company_name", data?.c_name);
      body.append("email", data?.email);
      body.append("mobile", data?.mobile);
      body.append("designation", data?.designation || "");
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + `Admin/Event.php`,
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);

      if (res.success) {
        toast.success(res.message);
        setQr_code(res?.qrcode);
        setData({});
      } else {
        toast.error(res.message);
      }

      // setLoading(false);
      // setData({});
      // toast.success(`Visitor added successfully`);
      // navigate("/");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <Container maxW={"container.xl"} py={5}>
      <Container maxW={"container.sm"} p={4}>
        <Flex display={"flex"} direction={"column"} gap={1}>
          <Heading textAlign={"center"} mb={1} size={["md", "lg", "xl"]}>
            {event_details?.Name}
          </Heading>
          <Heading textAlign={"center"} mb={4} size={["sm", "md", "lg"]}>
            {/* 14-Jan-2024 01:20 AM */}
            {`${moment(event_details?.Event_Date).format(
              "DD-MMM-YYYY"
            )} ${moment(event_details?.Event_Time, "hh:mm A").format(
              "hh:mm A"
            )}`}
          </Heading>
          <Heading textAlign={"center"} mb={1} size={["xs", "sm", "sm"]}>
            ( Visitors Only )
          </Heading>

          {qr_code ? (
            <Flex
              display={["flex"]}
              justify={"center"}
              align={"center"}
              gap={3}
              direction={"column"}
            >
              {/* <Text textAlign={"center"}>
                Go to the counter No. 1 and proccess your request
              </Text> */}
              <Flex
                h={["200px", "240px", "260px"]}
                w={["200px", "240px", "260px"]}
              >
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={qr_code}
                  alt=""
                />
              </Flex>
            </Flex>
          ) : (
            <>
              <InputBox
                req={true}
                label={"Full Name"}
                placeholder={"Full Name"}
                value={data.Name}
                onChange={(v) => {
                  const input = v.target.value.replace(/\s\s+/g, " ");
                  setData({ ...data, Name: input, NameMess: "" });
                }}
                errorMess={data.NameMess}
              />
              <InputBox
                req={true}
                label={"Company Name"}
                placeholder={"Company Name"}
                value={data.c_name}
                onChange={(v) => {
                  const input = v.target.value.replace(/\s\s+/g, " ");
                  setData({ ...data, c_name: input, c_nameMess: "" });
                }}
                errorMess={data.c_nameMess}
              />
              <InputBox
                req={true}
                label={"Email"}
                placeholder={"Email"}
                value={data.email}
                onChange={(v) => {
                  const input = v.target.value.replace(/\s\s+/g, " ");
                  setData({ ...data, email: input, emailMess: "" });
                }}
                errorMess={data.emailMess}
              />
              <InputBox
                req={true}
                label={"Mobile Number"}
                placeholder={"Mobile Number"}
                value={data.mobile}
                onChange={(v) => {
                  const mobile = v.target.value.replace(/\D/g, "").slice(0, 10);

                  if (mobile?.length <= 10) {
                    setData({ ...data, mobile: mobile, mobileMess: "" });
                  }
                }}
                errorMess={data.mobileMess}
              />

              <InputBox
                label={"Designation"}
                placeholder={"Designation"}
                value={data.designation}
                onChange={(v) => {
                  const input = v.target.value.replace(/\s\s+/g, " ");
                  setData({ ...data, designation: input, designationMess: "" });
                }}
                errorMess={data.designationMess}
              />

              <Center mt={3}>
                <CustomButton
                  title={"Submit"}
                  onClick={onAdd}
                  loading={loading}
                />
              </Center>
            </>
          )}
        </Flex>
      </Container>
    </Container>
  );
};

export default EventManagement;
