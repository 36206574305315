import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { get_gallery } from "../../../../redux/dataSlice";
// import store from "../../../redux/store";
import {
  Container,
  Grid,
  GridItem,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Loader } from "../../../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import ImageModal from "../../../../components/ImageModal";
import store from "../../../../redux/store";

const Gallery = () => {
  const { state } = useLocation();
  const { gallery_data, gallery_loading } = useSelector(
    (state) => state.allData
  );
  const [url, setUrl] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  useEffect(() => {
    store.dispatch(get_gallery());
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Group Gallery"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.xl"} p={4}>
        {gallery_loading && gallery_data?.length == 0 ? (
          <Loader />
        ) : gallery_data?.length == 0 ? (
          <Text>Working</Text>
        ) : (
          <Grid
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={5}
          >
            {gallery_data?.map((item, index) => (
              <GridItem
                key={index}
                w="100%"
                bg="#fff"
                shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                display={"flex"}
                flexDirection={"column"}
                cursor={"pointer"}
                onClick={() => {
                  navigate("/group-gallery/all-photos", { state: item });
                }}
              >
                <Image
                  src={item.Photo_Url}
                  h={"200"}
                  w={"100%"}
                  objectFit={"cover"}
                  alignSelf={"center"}
                />
                <Text
                  px={3}
                  my={2}
                  color={"brand.text"}
                  fontSize={16}
                  textAlign={"center"}
                >
                  {item.Event_name}
                </Text>
              </GridItem>
            ))}
          </Grid>
        )}
      </Container>
      <ImageModal url={url} isOpen={isOpen} onClose={onClose} />
    </Container>
  );
};

export default Gallery;
