import React, { useEffect, useState } from "react";
import { FaRegStarHalfStroke, FaStar } from "react-icons/fa6";
import { InputBox } from "../utils/LayoutUtils";
import { Text } from "@chakra-ui/react";

export const FeedBack = ({ data, setData }) => {
  const [hover, setHover] = useState(null);
  const [Feedback, setFeedback] = useState("");
  useEffect(() => {
    fetchData();
  }, [data?.rating]);

  const fetchData = () => {
    if (data?.rating !== 0) {
      if (data?.rating === 1) {
        setFeedback("VERY BAD");
      } else if (data?.rating === 2) {
        setFeedback("BAD");
      } else if (data?.rating === 3) {
        setFeedback("AVERAGE");
      } else if (data?.rating === 4) {
        setFeedback("GOOD");
      } else if (data?.rating === 5) {
        setFeedback("VERY GOOD");
      }
    } else {
      setFeedback("");
    }
  };
  return (
    <div className="Feed-main">
      <div className="feed-result">{Feedback}</div>
      <div className="feed-icons">
        {[...Array(5)].map((star, index) => {
          const starValue = index + 1;
          return (
            <label key={index}>
              <FaStar
                className="feed-icon"
                color={
                  starValue <= (hover || data?.rating) ? "#ffc107" : "#e4e5e9"
                }
                size={30}
                value={starValue}
                onClick={() =>
                  setData({ ...data, rating: starValue, ratingError: "" })
                }
                onMouseEnter={() => setHover(starValue)}
                onMouseLeave={() => setHover(null)}
              />
            </label>
          );
        })}
      </div>
      {data?.ratingError && (
        <Text color={"#f00"} fontSize={14} mt={1}>
          {data?.ratingError}
        </Text>
      )}
      <InputBox
        w={"full"}
        area={true}
        placeholder={"Tell us what you loved..."}
        onChange={(e) =>
          setData({ ...data, message: e.target.value, messError: "" })
        }
        value={data?.message}
        errorMess={data?.messError}
      />
    </div>
  );
};

// export const FeedBackbar = ({ rating }) => {
//   return (
//     <div className="feed-icons" style={{ margin: 0 }}>
//       {[...Array(5)].map((star, index) => {
//         const starValue = index + 1;
//         return (
//           <label key={index}>
//             <FaStar
//               cursor={"none"}
//               className="feed-icon"
//               color={starValue <= rating ? "#ffc107" : "#e4e5e9"}
//               size={16}
//             />
//           </label>
//         );
//       })}
//     </div>
//   );
// };

// import { FaStar, FaStarHalfAlt } from "react-icons/fa"; // FaStarHalfAlt for half star

export const FeedBackbar = ({ rating }) => {
  return (
    <div className="feed-icons" style={{ margin: 0 }}>
      {[...Array(5)].map((star, index) => {
        const starValue = index + 1;

        // Render a full star, half star, or empty star based on the rating
        return (
          <label key={index}>
            {starValue <= rating ? (
              <FaStar
                cursor={"none"}
                className="feed-icon"
                color="#ffc107"
                size={16}
              />
            ) : starValue - 0.5 <= rating ? (
              <FaRegStarHalfStroke
                cursor={"none"}
                className="feed-icon"
                color="#ffc107"
                size={18}
              />
            ) : (
              <FaStar
                cursor={"none"}
                className="feed-icon"
                color="#e4e5e9"
                size={16}
              />
            )}
          </label>
        );
      })}
    </div>
  );
};
