import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import { CustomButton, Loader } from "../../../../../utils/LayoutUtils";
import { ProductCard } from "../../../../../components/ProductCard";
import { useLocation, useNavigate } from "react-router-dom";
import { Table1 } from "../../../../../components/Compnent";
import { get_consumable_order_list } from "../../../../../redux/dataSlice";
import store from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { IoCheckmarkCircle } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import moment from "moment/moment";
import { toast } from "react-toastify";
import CustomPopup from "../../../../../components/CustomPopup";
import { MdOutlinePendingActions } from "react-icons/md";

export const ShopOrder = () => {
  const [loading, setLoading] = useState(false);
  const [updateType, setUpdateType] = useState("");
  const [id, setId] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("user");
  const [list, setlist] = useState([]);
  const user = JSON.parse(loggedInUser);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { consumable_order_list, consumable_order_loading } = useSelector(
    (state) => state.allData
  );
  const obj = { userid: user?.id, sub_cat: state?.id, service_id: 7 };

  useEffect(() => {
    store.dispatch(get_consumable_order_list(obj));
  }, []);

  const status_update = async () => {
    try {
      setLoading(true);
      // updateOrder(id,status)
      const body = new FormData();
      body.append("action", "updateOrder");
      body.append("id", id);
      body.append(
        "status",
        updateType == "Accept" ? "1" : updateType == "Reject" ? "2" : ""
      );
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Lets_shop.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        store.dispatch(get_consumable_order_list(obj));
        toast.success(res.message);
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Orders"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Our Offering", to: -3 },
          { name: "Consumables", to: -2 },
          { name: state?.name, to: -1 },
        ]}
      />
      <Container maxW={"container.md"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {consumable_order_loading ? (
          <Loader />
        ) : consumable_order_list.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          consumable_order_list?.map((item, index) => (
            <Box
              key={index}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "column"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={[5, "5%"]}
              position={"relative"}
              border={`1px solid ${
                item?.status == "0"
                  ? "#FFD700"
                  : item?.status == "1"
                  ? "green"
                  : item?.status == "2"
                  ? "red"
                  : ""
              }`}
            >
              <Text position={"absolute"} right={2} top={2}>
                {item?.status != "0" ? (
                  <>
                    {item?.status == "1" ? (
                      <IoCheckmarkCircle size={35} color={"green"} />
                    ) : item?.status == "2" ? (
                      <RxCrossCircled size={35} color={"red"} />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <MdOutlinePendingActions size={35} color={"#FFD700"} />
                )}
              </Text>
              <Text w={"full"} textAlign={"left"}>
                <span style={{ fontWeight: "bold", paddingRight: "1rem" }}>
                  Order Id:
                </span>
                {item?.order_id}
              </Text>
              <Text w={"full"} textAlign={"left"} pb={"1rem"}>
                <span style={{ fontWeight: "bold", paddingRight: "1rem" }}>
                  Order Date and Time:
                </span>
                {moment(item?.datetime).format("DD-MMM-YYYY hh:mm A")}
              </Text>
              <Flex flexDirection={"column"} w={"100%"}>
                <Table1
                  headData={[
                    "Poduct Id",
                    "Poduct Name",
                    "Quantity",
                    "Price",
                    "Discount",
                    "Total",
                  ]}
                  footer={
                    <Tr>
                      <Td textAlign={"center"} fontWeight={"bold"}>
                        Total
                      </Td>
                      <Td>-</Td>
                      <Td textAlign={"center"} fontWeight={"bold"}>
                        {item?.total_quantity}
                      </Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td textAlign={"center"} fontWeight={"bolder"}>
                        {item?.total_amount}
                      </Td>
                    </Tr>
                  }
                >
                  {item?.products.map((v, i) => (
                    <Tr key={i}>
                      <Td textAlign={"center"}>{v?.product_id}</Td>
                      <Td textAlign={"center"}>{"Naveen test "}</Td>
                      <Td textAlign={"center"}>{v?.quantity}</Td>
                      <Td textAlign={"center"}>{v?.Price}</Td>
                      <Td textAlign={"center"}>{v?.Discount}</Td>
                      <Td textAlign={"center"} fontWeight={"bold"}>
                        {v?.total}
                      </Td>
                    </Tr>
                  ))}
                </Table1>
              </Flex>
              <Flex
                mt={2}
                w={["full", "70%"]}
                alignItems={"flex-end"}
                flexDirection={["row", "row"]}
                justifyContent={"flex-end"}
                gap={"2"}
              >
                {item?.status == "0" ? (
                  <>
                    {" "}
                    <CustomButton
                      loading={loading && updateType == "Accept"}
                      w="full"
                      title={"Accept"}
                      bg={"brand.primary"}
                      onClick={() => {
                        setId(item?.ID);
                        setUpdateType("Accept");
                        onOpen();
                      }}
                    />
                    <CustomButton
                      w="full"
                      title={"Reject"}
                      bg={"brand.btn"}
                      loading={loading && updateType == "Reject"}
                      onClick={() => {
                        setId(item?.ID);
                        setUpdateType("Reject");
                        onOpen();
                      }}
                    />
                  </>
                ) : item?.status == "1" ? (
                  <CustomButton
                    w="full"
                    title={"Complete Order"}
                    bg={"brand.primary"}
                  />
                ) : (
                  ""
                )}
                <CustomButton
                  w="full"
                  title={"View/Download"}
                  bg={"black"}
                  onClick={() =>
                    navigate("/let's-shop/suplier-order-details", {
                      state: {
                        state: state,
                        itemData: item,
                      },
                    })
                  }
                />
              </Flex>
            </Box>
          ))
        )}
      </Container>

      <CustomPopup
        isOpen={isOpen}
        onClick={status_update}
        b_name={updateType}
        onClose={onClose}
        title={updateType}
        mess={`Are you sure? You want to ${updateType} this Service.`}
      />
    </Container>
  );
};
