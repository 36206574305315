import { Box, Container, Flex, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Loader } from "../utils/LayoutUtils";

const ListItem = ({ loading, list = [], url, title, onClick }) => {
  return (
    <GridItem
      maxW={250}
      w="100%"
      bg="#fff"
      shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
      borderRadius={10}
      alignItems={"center"}
      justifyContent={"space-evenly"}
      display={"flex"}
      flexDirection={"column"}
      cursor={"pointer"}
      p={2}
      gap={3}
      onClick={onClick}
    >
      <Image
        src={url}
        h={["120px", "70%"]}
        w={["120px", "70%"]}
        objectFit={"fill"}
      />
      <Text
        color={"#000"}
        fontSize={18}
        fontWeight={400}
        mb={3}
        textAlign={"center"}
      >
        {title}
      </Text>
    </GridItem>
  );
};

export default ListItem;
