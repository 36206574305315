import { Center, Container } from "@chakra-ui/react";
import React, { useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import {
  CustomButton,
  InputBox,
  SelectImage,
} from "../../../../utils/LayoutUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import store from "../../../../redux/store";
import { get_blogs } from "../../../../redux/dataSlice";

const AddBlog = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const mobile = localStorage.getItem("mobile");

  const onAddBlog = async () => {
    try {
      if (
        !data.title ||
        !data.message ||
        !data.reason ||
        !data.file.name ||
        !data.name
      ) {
        setData({
          ...data,
          nameMess: !data.name ? "Name is required" : "",
          messageMess: !data.message ? "Message is required" : "",
          titleMess: !data.title ? "Title is required" : "",
          reasonMess: !data.reason ? "Reason is required" : "",
          fileMess: !data.file?.name ? "File is required" : "",
        });
        return;
      }
      setLoading(true);
      const body = new FormData();
      body.append("action", "create");
      body.append("Mobile_Number", mobile);
      body.append("Name", data.name);
      body.append("Title", data.title);
      body.append("message", data.message);
      body.append("Reason", data.reason);
      body.append("image", data.file);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "blogs.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        navigate(-1);
        const body1 = new FormData();
        body1.append("action", "list");
        store.dispatch(get_blogs(body1));
      } else {
        toast.error(res.message);
      }
    } catch (error) {}
  };
  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Add Blog"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Blogs", to: "/blogs" },
        ]}
      />

      <Container maxW={"container.sm"} p={4}>
        <InputBox
          label={"Name"}
          placeholder={"Name"}
          value={data.name}
          onChange={(v) => {
            setData({ ...data, name: v.target.value, nameMess: "" });
          }}
          errorMess={data.nameMess}
        />
        <InputBox
          label={"Title"}
          placeholder={"Title"}
          value={data.title}
          onChange={(v) => {
            setData({ ...data, title: v.target.value, titleMess: "" });
          }}
          errorMess={data.titleMess}
        />
        <InputBox
          label={"Message"}
          placeholder={"Message"}
          area={true}
          value={data.message}
          onChange={(v) => {
            setData({ ...data, message: v.target.value, messageMess: "" });
          }}
          errorMess={data.messageMess}
        />
        <InputBox
          label={"Reason"}
          placeholder={"Reason"}
          area={true}
          value={data.reason}
          onChange={(v) => {
            setData({ ...data, reason: v.target.value, reasonMess: "" });
          }}
          errorMess={data.reasonMess}
        />
        <SelectImage
          label={"Image"}
          doctype={".png,.jpg,.jpeg"}
          filename={data?.file?.name}
          errorMess={data?.fileMess}
          onChange={(e) => {
            setData({ ...data, file: e.target.files[0], fileMess: "" });
          }}
        />
        <Center mt={6}>
          <CustomButton
            title={"Add Blog"}
            onClick={() => onAddBlog()}
            loading={loading}
          />
        </Center>
      </Container>
    </Container>
  );
};

export default AddBlog;
