import { extendTheme } from "@chakra-ui/react";

export const images = {
  logo: require("../assets/lpg-logo.jpeg"),
  googleplay: require("../assets/googleplay.png"),
  appstore: require("../assets/appstore.png"),
};

const customColors = {
  primary: "#2b3478",
  brand: {
    primary: "#2b3478",
    black: "#000000",
    btn: "#d1262b",
    white: "#fff",
    text: "#2b3478",
    text1: "#A8B1B3",
    text2: "#000000",
  },
};
export const theme = extendTheme({
  fonts: {
    heading: `'Lato', sans-serif`,
    body: `'Lato', sans-serif`,
  },
  colors: { ...customColors },
});

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const DateValidator2 = (
  dateValue,
  endDate = "",
  validateDate = "",
  mess1 = "",
  mess2 = ""
) => {
  const [dayA, monthA, yearA] = dateValue.split("/").map(Number);
  const [dayB, monthB, yearB] = validateDate.split("/").map(Number);
  const [dayC, monthC, yearC] = endDate.split("/").map(Number);
  const dateA = new Date(yearA, monthA - 1, dayA);
  const dateB = new Date(yearB, monthB - 1, dayB);
  const dateC = new Date(yearC, monthC - 1, dayC);
  const errors = [];
  if (dateA < dateB) {
    errors.push(mess1);
  } else if (dateA > dateC) {
    errors.push(mess2);
  }

  if (errors.length > 0) {
    return errors;
  } else {
    return true;
  }
};

export const calculateExperience = (months) => {
  if (months < 12) {
    return `${months} month${months > 1 ? "s" : ""}`;
  } else {
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return `${years} year${years > 1 ? "s" : ""}, ${
      remainingMonths > 0
        ? `${remainingMonths} month${remainingMonths > 1 ? "s" : ""}`
        : ""
    }`;
  }
};

export const BorderFunction = (status) => {
  return `1px solid ${
    status == "0"
      ? "orange"
      : status == "1"
      ? "green"
      : status == "2"
      ? "red"
      : "orange"
  }`;
};


export const TypeOfVehicle = [
  {
    value:0,lable:"TWO WHEELER",
  },
  {
    value:1,lable:"THREE WHEELER",
  },
  {
    value:2,lable:"FOUR WHEELER",
  },
  {
    value:3,lable:"TRUCK",
  },
  {
    value:4,lable:"OTHERS",
  },
]


export function validateEmail2(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
  return emailRegex.test(email);
}

// Function to validate mobile number
export function validateMobile(mobile) {
  const mobileRegex = /^[6-9]\d{9}$/; // 10-digit number, starting with 6-9
  return mobileRegex.test(mobile);
}
