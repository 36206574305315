import { Flex, Stack, Text } from "@chakra-ui/react";
import React, { useRef } from "react";
import { GoDotFill } from "react-icons/go";
import {
  DotHeading,
  HeadingMain,
  HeadingSub,
  HeadingText,
} from "../../components/Compnent";

const About = ({ aboutRef, data }) => {
  return <>{data && <div dangerouslySetInnerHTML={{ __html: data }} />}</>;
};

export default About;

{
  /* <HeadingMain title={"About Us"} ref={aboutRef} />

      <Flex
        className={"col"}
        display={"flex"}
        flexDirection={["column", "column", "column", "row"]}
        gap={5}
      >
        <Stack flex={6} flexGrow={6} spacing={0}>
          <HeadingSub title={"Description"} />
          <HeadingText
            title={`Established in 2016 under the Societies Act of Telangana, the
            Telangana LPG Distributors Association is a dynamic force actively
            engaged in promoting the welfare of LPG distributors across the
            state. Our association plays a pivotal role in fostering
            constructive cooperation among member LPG distributors, maintaining
            high business standards, and advocating for the efficient and
            profitable operation of LPG distributorships and LPG Supply."`}
          />
          <HeadingSub title={"Advocacy for a Flourishing Industry"} />
          <HeadingText
            title={` We actively engage in dialogue with key stakeholders, including Oil
            Marketing Companies, the State Government Civil Supplies Department,
            PESO (Petroleum and Explosives Safety Organization),and the Legal
            Metrology Department etc. This ensures the smooth functioning of LPG
            distributorships in Telangana, promoting a flourishing industry and
            superior customer experience.`}
          />
        </Stack>

        <img
          src={"https://picsum.photos/500/400"}
          style={{
            flex: 4,
            paddingTop: "1rem",
            height: 400,
            width: "600px !important",
            objectFit: "cover",
          }}
        />
      </Flex>
      <HeadingSub title={" National Policy Advocacy"} />
      <HeadingText
        title={`
        At the national level, we collaborate with our affiliated national
        association, FLDI (Federation of LPG Distributors of India),to
        contribute to the LPG trade. This coordination allows us to influence
        and shape policies that benefit our members and the industry as a whole`}
      />
      <HeadingSub title={"Our Objectives"} />
      <HeadingText
        title={`As outlined in our bylaws, the objectives of the Telangana LPG
            Distributors Association are.`}
      />
      <DotHeading title={"Promoting Constructive Cooperation"} />
      <HeadingText
        pl={["22px", "24px", "26px", "28px"]}
        title={
          " Foster cooperation among member LPG distributors, their sources of supply (OMCs),and services that advance mutual interests"
        }
      />
      <DotHeading title={"High Business Standards"} />

      <HeadingText
        pl={["22px", "24px", "26px", "28px"]}
        title={
          " Inspire the observance of high business methods for efficient and profitable operations."
        }
      />

      <DotHeading title={"Advocacy for Sound Policies and Practices"} />
      <HeadingText
        pl={["22px", "24px", "26px", "28px"]}
        title={
          "  Encourage adherence to sound policies and practices, correcting faulty         procedures, and eliminating commercial evils"
        }
      />
      <DotHeading title={" Retaining Identity as an Essential Service"} />
      <HeadingText
        pl={["22px", "24px", "26px", "28px"]}
        title={
          "Ensure that LPG distributors retain their place and identity as an         essential and efficient service in the distribution of LPG"
        }
      />
      <DotHeading title={"Lawful Acts for Welfare"} />
      <HeadingText
        pl={["22px", "24px", "26px", "28px"]}
        title={
          "Engage in any and all lawful acts or activities that promote the welfare         of LPG distributors, adhering to the legal framework for not-for-profit organizations Join Us in Shaping the FutureTogether, let s shape a future where efficiency, cooperation, and prosperity define the landscape of LPG distribution"
        }
      /> */
}
