import {
  Box,
  Container,
  Flex,
  Grid,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../redux/store";
import { get_category } from "../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import { Loader } from "../../../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import { get_policy_company_list } from "../../../../redux/otherSlice";
import CustomPopup from "../../../../components/CustomPopup";
import ListItem from "../../../../components/ListItem";

const LetsShop = () => {
  const { state } = useLocation();
  const { categoty_data, cat_loading } = useSelector((state) => state.allData);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { type } = useSelector((state) => state.otherData);
  useEffect(() => {
    const body = new FormData();
    body.append("action", "Details");
    body.append("id", 7);
    store.dispatch(get_category(body));
    store.dispatch(get_policy_company_list());
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Let's Shop"}
        titlelist={[
          { name: "Home", to: "/" },
          type == 1 && { name: "Our Offering", to: "/home" },
        ]}
      />
      <Container maxW={"container.xl"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {cat_loading && categoty_data?.length == 0 ? (
          <Loader />
        ) : categoty_data?.length == 0 ? (
          <Text>Working</Text>
        ) : (
          <Grid
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={[5, 5, 10]}
          >
            {categoty_data?.map((item, index) => (
              <ListItem
                key={index}
                title={item.name}
                url={item.Web_Images}
                onClick={() => navigate("/let's-shop/lets-show-main",{state:item})}
              />
              
            ))}
          </Grid>
        )}
      </Container>
      <CustomPopup
        fontSize={40}
        isOpen={isOpen}
        onClose={onClose}
        // title={"Oops"}
        mess={"Coming soon..."}
        single_button={true}
        b_color={"#2b3478"}
        b_name={"ok"}
      />
    </Container>
  );
};

export default LetsShop;
