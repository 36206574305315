import React from "react";
import { Table1 } from "./Compnent";
import { Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

export const TableAddProd = ({ lable, item, ReMid = true,bold="400",fontSize="16px" }) => {
  return (
    <Tr>
      <Td fontSize={fontSize} fontWeight={bold} p={2}>{lable}</Td>
      {ReMid && <Td p={2}>:</Td>}
      <Td fontSize={fontSize} fontWeight={bold} p={2}>{item}</Td>
    </Tr>
  );
};

export const TableMultData = ({ lable, item, ReMid = true,bold="400",fontSize="16px",childern,headers }) => {
  return (
    <>
    <Thead>
      {headers?.map((v,i)=>(
        <Th key={i}>{v}</Th>
      ))}
    </Thead>
    <Tbody>
   {childern}
    </Tbody>
    </>
  );
};
