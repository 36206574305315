import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { images } from "../../utils/utils";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { CustomButton, InputBox } from "../../utils/LayoutUtils";
import { onForgotOTP, onResetPassword, onSendOTP } from "../../utils/apis";
import { toast } from "react-toastify";
import { FiEye, FiEyeOff } from "react-icons/fi";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [show, setShow] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const onSend = () => {
    if (!data.mobile || (data?.mobile && data.mobile?.length != 10)) {
      setData({
        ...data,
        mobileMess: !data.mobile
          ? "Mobile Number is required"
          : data.mobile?.length != 10
          ? "Mobile Number must Contain 10 Digit"
          : "",
      });
      return;
    }
    const body = new FormData();
    body.append("Mobile_Number", data.mobile);
    onForgotOTP(body, setLoading)
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          setShow(true);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => console.log(error));
  };
  const onHandleClick = () => {
    if (
      !data.mobile ||
      (data.mobile && data.mobile?.length != 10) ||
      !data.otp ||
      (data.otp && data.otp?.length != 6) ||
      !data.pass ||
      (data.pass && data.pass?.length < 8) ||
      !data.cPass ||
      (data.cPass && data.cPass?.length < 8) ||
      data.pass != data.cPass
    ) {
      setData({
        ...data,
        mobileMess: !data.mobile
          ? "Mobile Number is required"
          : data.mobile?.length != 10
          ? "Mobile Number must Contain 10 Digit"
          : "",
        otpMess: !data.otp
          ? "Otp is required"
          : data.otp?.length != 6
          ? "Otp Must be of 6 Digit"
          : "",
        passMess: !data.pass
          ? "New Password is required"
          : data.pass?.length < 8
          ? "New Password must Contain Min 6 Digit"
          : "",
        cPassMess: !data.cPass
          ? "Confirm Password is required"
          : data.cPass?.length < 8
          ? "Confirm Password must Contain Min 6 Digit"
          : data.pass != data.cPass
          ? "New and Confirm Password Not Matched"
          : "",
      });
      return;
    }
    const body = new FormData();
    body.append("Mobile", data.mobile);
    body.append("otp", data.otp);
    body.append("Password", data.pass);
    body.append("Cpassword", data.cPass);
    onResetPassword(body, setLoading1)
      .then((res) => {
        if (res.success) {
          toast.success(res.msg);
          navigate(-1);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <Container
      // alignItems={["center", "center", "center", "center"]}
      // justifyContent={["flex-start", "flex-start", "flex-start", "flex-start"]}
      alignItems={"center"}
      justifyContent={"flex-start"}
      minW="100vw"
      display={"flex"}
      flexDirection={"column"}
      p={0}
    >
      <Container maxW={"container.lg"} pt={5}>
        <Flex alignSelf={"flex-start"} alignItems={"center"} gap={5}>
          <IoArrowBackOutline
            size={30}
            color="#2b3478"
            cursor={"pointer"}
            onClick={() => navigate(-1)}
          />
          <Text fontSize={[18, 22, 26, 30]} fontWeight={600} color={"#2b3478"}>
            Forgot Password
          </Text>
        </Flex>
      </Container>

      <Stack p={5} maxW="lg" w={"100%"} mt={["10px", 10, 20, 70]}>
        <Image
          src={images.logo}
          h={["120px", "150px", "150px", "150px"]}
          w={["120px", "150px", "150px", "150px"]}
          objectFit={"contain"}
          alignSelf={"center"}
        />
        <Heading
          fontSize={[16, 18, 20]}
          textAlign={"center"}
          py={2}
          fontWeight={600}
        >
          LPG Association
        </Heading>

        <Flex gap={3} flexDirection={["column", "row"]}>
          <InputBox
            disable={show}
            placeholder={"Mobile Number"}
            mt={2}
            value={data.mobile}
            maxLength={10}
            onChange={(e) =>
              setData({
                ...data,
                mobile: e.target.value.trim().replace(/[^0-9 ]/g, ""),
                mobileMess: "",
              })
            }
            errorMess={data?.mobileMess}
          />
          <Box
            display={"flex"}
            h={"fit-content"}
            mt={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CustomButton
              title={show ? "Resend OTP" : "Send OTP"}
              mt={2}
              onClick={onSend}
              loading={loading}
            />
          </Box>
        </Flex>
        {show && (
          <>
            <InputBox
              placeholder={"Enter OTP"}
              type={showPass ? "text" : "password"}
              value={data.otp}
              onChange={(e) => {
                const input = e.target.value.replace(/\D/g, "");
                if (input.length <= 6) {
                  setData({ ...data, otp: input, otpMess: "" });
                }
              }}
              RightElement={
                showPass ? (
                  <FiEyeOff
                    size={20}
                    color="#000"
                    onClick={() => setShowPass(!showPass)}
                  />
                ) : (
                  <FiEye
                    size={20}
                    color="#000"
                    onClick={() => setShowPass(!showPass)}
                  />
                )
              }
              errorMess={data?.otpMess}
            />
            <InputBox
              type={showPass1 ? "text" : "password"}
              placeholder={"New Password"}
              value={data.pass}
              onChange={(e) =>
                setData({ ...data, pass: e.target.value, passMess: "" })
              }
              RightElement={
                showPass1 ? (
                  <FiEyeOff
                    size={20}
                    color="#000"
                    onClick={() => setShowPass1(!showPass1)}
                  />
                ) : (
                  <FiEye
                    size={20}
                    color="#000"
                    onClick={() => setShowPass1(!showPass1)}
                  />
                )
              }
              errorMess={data?.passMess}
            />
            <InputBox
              type={showPass2 ? "text" : "password"}
              placeholder={"Confirm Password"}
              value={data.cPass}
              onChange={(e) =>
                setData({ ...data, cPass: e.target.value, cPassMess: "" })
              }
              RightElement={
                showPass2 ? (
                  <FiEyeOff
                    size={20}
                    color="#000"
                    onClick={() => setShowPass2(!showPass2)}
                  />
                ) : (
                  <FiEye
                    size={20}
                    color="#000"
                    onClick={() => setShowPass2(!showPass2)}
                  />
                )
              }
              errorMess={data?.cPassMess}
            />
            <CustomButton
              title={"Submit"}
              mt={5}
              onClick={onHandleClick}
              loading={loading1}
            />
          </>
        )}
      </Stack>
    </Container>
  );
};

export default ResetPassword;
