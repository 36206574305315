import {
  Card,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { HeadingMain } from "../../components/Compnent";
import { ourTeamData } from "../../utils/data";

const OverTeam = ({ Ref, data }) => {
  return (
    data && (
      <>
        <HeadingMain title={"Our Team"} ref={Ref} />
        <Container maxW={"container.lg"} p={[1, 1, 2, 4]}>
          {/* <SimpleGrid columns={[1, 2, 3]} spacing={5}> */}
          {data?.map((item, index) => (
            <Flex
              key={index}
              bg="#fff"
              gap={[3, 6, 8, 10]}
              mt={[0, 3, 4, 5]}
              flexDirection={[
                "column",
                "column",
                index % 2 == 0 ? "row" : "row-reverse",
              ]}
              p={[0, 3, 4, 5]}
            >
              <Image
                // src={"https://picsum.photos/500/400"}
                src={item?.Photo}
                h={"300"}
                w={"300"}
                objectFit={"fill"}
                alignSelf={"center"}
                borderRadius={10}
              />
              <Stack p={5}>
                <Text color={"brand.primary"} fontSize={20} fontWeight={600}>
                  {item?.Name}
                </Text>
                <Text color={"brand.black"} fontSize={16} fontWeight={600}>
                  {item?.Role}
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={400}>
                  {item?.Description}
                </Text>
              </Stack>
            </Flex>
          ))}
          {/* </SimpleGrid> */}
        </Container>
      </>
    )
  );
};

export default OverTeam;
