import { Box, Container, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../redux/store";
import { get_category } from "../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import { Loader } from "../../../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import ListItem from "../../../../components/ListItem";

const Training = () => {
  const { state } = useLocation();
  const { categoty_data, cat_loading } = useSelector((state) => state.allData);
  const navigate = useNavigate();
  useEffect(() => {
    const body = new FormData();
    body.append("action", "Details");
    body.append("id", 9);
    store.dispatch(get_category(body));
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Training & Development"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.xl"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {cat_loading && categoty_data?.length == 0 ? (
          <Loader />
        ) : categoty_data?.length == 0 ? (
          <Text>Working</Text>
        ) : (
          <Grid
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={[5, 5, 10]}
          >
            {categoty_data?.map((item, index) => (
              <ListItem
                key={index}
                title={item.name}
                url={item.Web_Images}
                onClick={() => {
                  navigate("/training/askfortraining", {
                    state: {
                      cat_id: 9,
                      subCat_id: item.id,
                      name: item.name,
                    },
                  });
                }}
              />
            ))}
          </Grid>
        )}
      </Container>
    </Container>
  );
};

export default Training;
