import {
  AspectRatio,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ImageModal = ({ url = "", isOpen, onClose }) => {
  const [typeData, setTypeData] = useState("");

  useEffect(() => {
    const data = url?.split(".");
    const data1 = data[data?.length - 1];
    setTypeData(data1);
  }, [url]);

  const [loading, setLoading] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const previousPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const nextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };
  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        url = "";
      }}
    >
      <ModalOverlay />
      <ModalContent
        maxW={"container.xl"}
        minH={["auto", "260px"]}
        maxH={"auto"}
        h={"auto"}
        w={"full"}
      >
        <ModalCloseButton zIndex={100} bg={"#99999988"} />
        <ModalBody h={"full"} display={"flex"} justifyContent={"center"}>
          {typeData == "mp4" ? (
            <AspectRatio
              minW={["100%", "100%"]}
              minH={["100%", "50%"]}
              ratio={2}
            >
              <iframe src={url} allowFullScreen style={{ border: "none" }} />
            </AspectRatio>
          ) : typeData == "pdf" ? (
            <>
              <div className="container">
                <Document
                  file={url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={(error) =>
                    console.error("Error while loading document:", error)
                  }
                >
                  <div className="pageWrapper">
                    <Page
                      pageNumber={pageNumber}
                      width={
                        window.innerWidth > 500
                          ? window.innerWidth / 1.9
                          : window.innerWidth
                      }
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  </div>
                </Document>
                <div
                  className="footer_data"
                  style={{
                    width:
                      window.innerWidth > 500
                        ? window.innerWidth / 1.9
                        : "80vw",
                  }}
                >
                  <button disabled={pageNumber === 1} onClick={previousPage}>
                    Previous
                  </button>
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                  <button disabled={pageNumber === numPages} onClick={nextPage}>
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : (
            <Image
              src={url}
              maxW={"container.xl"}
              w={"80vw"}
              objectFit={"contain"}
              maxH={"90%"}
              h={"80vh"}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;

// import React, { useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import { ModalBody, AspectRatio, Image } from "@chakra-ui/react";

// // Set the worker path
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// const ImageModal = ({ typeData, url }) => {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

//   return (
//     <ModalBody h={"full"} display={"flex"} justifyContent={"center"}>
//       {typeData === "mp4" ? (
//         <AspectRatio minW={["100%", "100%"]} minH={["100%", "50%"]} ratio={2}>
//           <iframe src={url} allowFullScreen style={{ border: "none", width: '100%', height: '100%' }} />
//         </AspectRatio>
//       ) : typeData === "pdf" ? (
//         <div style={{ width: '100%', height: '100%' }}>
//           <Document
//             file={url}
//             onLoadSuccess={onDocumentLoadSuccess}
//           >
//             <Page pageNumber={pageNumber} />
//           </Document>
//           <p>
//             Page {pageNumber} of {numPages}
//           </p>
//           {numPages > 1 && (
//             <div>
//               <button disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>
//                 Previous
//               </button>
//               <button disabled={pageNumber >= numPages} onClick={() => setPageNumber(pageNumber + 1)}>
//                 Next
//               </button>
//             </div>
//           )}
//         </div>
//       ) : (
//         <Image
//           src={url}
//           maxW={"container.xl"}
//           w={"80vw"}
//           objectFit={"contain"}
//           maxH={"90%"}
//           h={"80vh"}
//         />
//       )}
//     </ModalBody>
//   );
// };

// export default ImageModal;
