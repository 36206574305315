import { Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { HeadingMain, HeadingSub } from "../../components/Compnent";

const JoinUs = ({ Ref, data }) => {
  return (
    <>
      <HeadingMain title={"Join With Us"} />
      {data?.map((v, i) => (
        <Text
          color={"#000"}
          fontSize={16}
          fontWeight={400}
          textAlign={"center"}
        >
          {v?.Text}
          {i != data?.length - 1 && <Spacer h={2} />}
        </Text>
      ))}
    </>
  );
};

export default JoinUs;
