import { Container, Grid, GridItem, Heading, Image, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../utils/LayoutUtils";
import { SupliersHome } from "../../utils/data";

const HomeSuplier = () => {
  const { home_data, home_loading } = useSelector((state) => state.allData);
  const loggedInUser = localStorage.getItem("user");
  const navigate = useNavigate();

  const user = JSON.parse(loggedInUser);

  return (
    <Container maxW={"container"} py={5} px={5}>
      <Heading color={"#2b3478"} fontSize={[21, 24, 27, 30]} fontWeight={800}>
        Hi {user?.name}
      </Heading>
      <Heading color={"brand.text2"} fontSize={[18, 21, 23, 25]} mt={[2, 4]}>
        Welcome to Telangana LPG Distributors Association
      </Heading>
      <Spacer h={[4, 6, 8, 10]} />

      <Container maxW={"container.xl"} p={[1, 2, 3, 4]}>
          {home_loading ? (
            <Loader />
          ) : (
            <Grid
              templateColumns={[
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(4, 1fr)",
              ]}
              gap={[5, 5, 10]}
            >
              {SupliersHome?.map((item, index) => (
                <GridItem
                  key={index}
                  w="100%"
                  bg="#fff"
                  shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                  borderRadius={10}
                  alignItems={"center"}
                  justifyContent={"center"}
                  display={"flex"}
                  flexDirection={"column"}
                  cursor={"pointer"}
                  p={2}
                  onClick={() => {
                        navigate(item.path, { state: item });
                  }}
                >
                  <Image
                    src={item.img}
                    h={["100%", "70%"]}
                    w={["100%", "70%"]}
                    objectFit={"fill"}
                    p={5}
                    alignSelf={"center"}
                  />
                  <Text
                    color={"#000"}
                    fontSize={18}
                    fontWeight={400}
                    mb={3}
                    textAlign={"center"}
                  >
                    {item.lable}
                  </Text>
                </GridItem>
              ))}
            </Grid>
          )}
        </Container>
    </Container>
  );
};

export default HomeSuplier;
