import React, { useEffect, useRef, useState } from "react";
import "swiper/css/pagination";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import {  Navigation, Controller, Autoplay } from "swiper/modules";
import { Flex, Image, Spacer, Text } from "@chakra-ui/react";
import { GrNext, GrPrevious } from "react-icons/gr";
import { HeadingMain } from "../../components/Compnent";
import { useSelector } from "react-redux";

const GalleryMain = ({ Ref,data }) => {
  const swiperRef = useRef();
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const { win_width } = useSelector((state) => state.otherData);
  return (
    <>
      <Flex justifyContent={"space-between"} ref={Ref}>
        <Flex w={100}></Flex>
        <HeadingMain title={"Gallery"} />
        <Flex
          w={100}
          gap={5}
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
        >
          <GrPrevious
            color="#000"
            size={25}
            style={{ cursor: "pointer" }}
            onClick={() => swiperRef.current.slidePrev()}
          />

          <GrNext
            color="#000"
            size={25}
            style={{ cursor: "pointer" }}
            onClick={() => swiperRef.current.slideNext()}
          />
        </Flex>
      </Flex>
      <Spacer h={10} />
      <div style={{ paddingBottom: 20 }}>
        <Swiper
          loop={true}
          ref={swiperRef}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          slidesPerView={
            win_width < 500 ? 1 : win_width < 900 ? 2 : win_width < 1200 ? 3 : 3
          }
          spaceBetween={30}
          modules={[Autoplay, Navigation, Controller]}
          controller={{ control: controlledSwiper }}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <Image
                src={item?.Image}
                h={"350px"}
                w={"100%"}
                objectFit={"fill"}
                alignSelf={"center"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default GalleryMain;
