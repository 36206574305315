import {
  Box,
  Center,
  Container,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";

const UsefullLinks = () => {
  const { state } = useLocation();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const get_data = async () => {
    try {
      setLoading(true);

      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "useful_link.php",
        { method: "POST" }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setList(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    get_data();
  }, []);
  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Useful Links"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.lg"} p={4}>
        {loading ? (
          <Loader />
        ) : (
          list.map((item, index) => (
            <Flex
              key={index}
              shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
              p={5}
              borderRadius={10}
              mt={5}
              bg={"#fff"}
              flexDirection={"column"}
            >
              <Text fontSize={[16,18,20]} color={"#2b3478"} fontWeight={600}>
                {item.Title}
              </Text>

              <Text fontSize={[12,13,14]} color={"brand.black"} fontWeight={400} py={2}>
                {item.Description}
              </Text>
              <Center alignSelf={"start"}>
                <CustomButton
                  title={"Go to link"}
                  onClick={() => window.open(item.Url)}
                />
              </Center>
            </Flex>
          ))
        )}
      </Container>
    </Container>
  );
};

export default UsefullLinks;
