import { Container, Flex, Text } from "@chakra-ui/react";
import React from "react";
import BreadCrumName from "../../components/BreadCrumName";
import { useLocation } from "react-router-dom";

const NotificationDetail = () => {
  const { state } = useLocation();

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Notification Details"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Notifications", to: "/notifications" },
        ]}
      />

      <Container maxW={"container.lg"} py={5}>
        <Flex alignItems={"center"}>
          <Text
            color={"#2b3478"}
            flex={1}
            fontSize={[18, 19, 20]}
            fontWeight={500}
          >
            {state.title}
          </Text>
        </Flex>

        <Text
          color={"brand.text2"}
          fontSize={[15, 16, 17]}
          fontWeight={500}
          mt={5}
        >
          {state?.message} 
        </Text>
        <Text
          textAlign={"right"}
          color={"brand.text2"}
          fontSize={[16, 17, 18]}
          fontWeight={500}
          mt={5}
        >
          {state?.datetime}
        </Text>
      </Container>
    </Container>
  );
};

export default NotificationDetail;
