import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../components/BreadCrumName";
import { Loader } from "../../utils/LayoutUtils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { get_downloads } from "../../redux/dataSlice";
import ImageModal from "../../components/ImageModal";

const Downloads = () => {
  const { download_loading, download_list } = useSelector(
    (state) => state.allData
  );
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    store.dispatch(get_downloads());
  }, []);
  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Downloads"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.lg"} p={4}>
        {download_loading ? (
          <Loader />
        ) : download_list?.length == 0 ? (
          <Text>No Data Found</Text>
        ) : (
          <Accordion allowMultiple bg={"#fff"}>
            {download_list?.map((item, index) => (
              <AccordionItem key={index}>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={18}
                    color={"#2b3478"}
                    fontWeight={"600"}
                  >
                    {item.Subject}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel pb={4}>
                  {item.subcategory?.map((v, i) => (
                    <Stack
                      key={i}
                      bg={"#F2F7FB"}
                      padding={2}
                      spacing={0}
                      mt={2}
                      cursor={"pointer"}
                      onClick={() => {
                        window.open(v.Link);
                        // setUrl(v.Link);
                        // onOpen();
                      }}
                    >
                      <Text
                        fontSize={[15,16,17,18]}
                        color={"brand.text"}
                        fontWeight={"600"}
                      >
                        Title: {v.Title}
                      </Text>
                      <Text
                        fontSize={[13,14,15,16]}
                        color={"brand.black"}
                        fontWeight={"400"}
                        lineHeight={"20px"}
                        mt={1}
                      >
                        Description: {v.Text}
                      </Text>
                    </Stack>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </Container>
      <ImageModal url={url} isOpen={isOpen} onClose={onClose} />
    </Container>
  );
};

export default Downloads;
