import {
  Box,
  Center,
  Container,
  Flex,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import {
  ListDataShow,
  ShowMoreText,
  Table1,
} from "../../../../components/Compnent";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import store from "../../../../redux/store";
import { get_insu_mem_view_list } from "../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import { downloadFile } from "../../../../utils/Hook/DownloadFile";
import CustomPopup from "../../../../components/CustomPopup";
import { toast } from "react-toastify";
import { TypeOfVehicle } from "../../../../utils/utils";

const ViewInsMem = () => {
  const [list, setlist] = useState([1, 2, 2, 3]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showTable, setShowTable] = useState("");
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { insu_mem_view_list, insu_mem_view_loading } = useSelector(
    (state) => state.allData
  );

  console.log("insu_mem_view_list ===", insu_mem_view_list);

  useEffect(() => {
    store.dispatch(get_insu_mem_view_list({ userid: user?.id }));
  }, []);

  const update_status = async () => {
    onClose();
    try {
      // quotationUpdate(id,category_id,sub_category_id,user_id,supplier_id,status)
      setLoading(true);
      const body = new FormData();
      body.append("action", "quotationUpdate");
      body.append("id", data?.id);
      body.append("user_id", user?.id);
      // body.append("supplier_id", user?.id);
      body.append("status", data?.status);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Insurance.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        setData({});
        store.dispatch(get_insu_mem_view_list({ userid: user?.id }));
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Quotation View"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Insurance", to: -1 },
        ]}
      />
      <Container maxW="container.md" mt={[5, 5]} p={[1, 2, 3, 4]}>
        {loading || insu_mem_view_loading ? (
          <Loader />
        ) : insu_mem_view_list?.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          insu_mem_view_list?.map((v, index) => (
            <Box
              key={index}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "column"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={[5, "5%"]}
            >
              <Flex flexDirection={"column"} w={"100%"} gap={0}>

              <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Type of Request / Insurance"}
                  value={v?.insurance_type}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Company"}
                  value={v?.company}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Name"}
                  value={v?.Name}
                />
                {v?.PName && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Proprietory Name"}
                    value={v?.PName}
                  />
                )}
                {v?.Address && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Address"}
                    value={v?.Address}
                  />
                )}
                {v?.Location && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Location"}
                    value={v?.Location}
                  />
                )}
                {v?.DOB && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Date of Birth"}
                    value={v?.DOB}
                  />
                )}
                {v?.Reg_num && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Vehicle Number"}
                    value={v?.Reg_num}
                  />
                )}

                {v?.Type_vehicle && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Type of Vehicle"}
                    value={
                      TypeOfVehicle?.filter(
                        (val, ind) => val?.value == v?.Type_vehicle
                      )?.[0]?.lable
                    }
                  />
                )}

                {v?.Model && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Make/Model"}
                    value={v?.Model}
                  />
                )}

                {v?.Mfg_year && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Mfg. year"}
                    value={v?.Mfg_year}
                  />
                )}
                {v?.son_wife_name && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Son/Wife"}
                    value={v?.son_wife_name}
                  />
                )}

                {(v?.Life_Cover || v?.Life_cover) && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Life Cover"}
                    value={v?.Life_Cover || v?.Life_cover}
                  />
                )}

                {(v?.Coverage_Till || v?.Coverage) && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Coverage Till Date"}
                    value={v?.Coverage_Till || v?.Coverage}
                  />
                )}

                {v?.Gender && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Gender"}
                    value={v?.Gender}
                  />
                )}

                {v?.Age && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Your Age"}
                    value={v?.Age}
                  />
                )}

                {v?.Gender && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Gender"}
                    value={v?.Gender}
                  />
                )}

                {/* <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Age"}
                  value={"25"}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"State"}
                  value={"Madhya Pradesh"}
                /> */}
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Description"}
                  value={v?.Description}
                />


                {/* <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Type of Request / Insurance"}
                  value={v?.insurance_type}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Name"}
                  value={v?.Name}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Age"}
                  value={"25"}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"State"}
                  value={"Madhya Pradesh"}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Description"}
                  value={v?.Description}
                /> */}
              </Flex>
              {v?.status_details?.length > 0 && (
                <Flex
                  w={"full"}
                  direction={"flex"}
                  justifyContent={["flex-end", "flex-end"]}
                  alignItems={["flex-end", "flex-end"]}
                  flexDirection={["row", "row"]}
                  gap={[2, 0]}
                  textAlign={"right"}
                >
                  <Text
                    w={"fit-content"}
                    display={"flex"}
                    textAlign={"right"}
                    color={"brand.primary"}
                    gap={4}
                    cursor={"pointer"}
                    onClick={() => {
                      if (showTable == index + 1) {
                        setShowTable("");
                      } else {
                        setShowTable(index + 1);
                      }
                    }}
                  >
                    {showTable == index + 1 ? "Less" : "Show"} Quotations
                  </Text>
                </Flex>
              )}

              {showTable == index + 1 && (
                <Table1
                  headData={[
                    "Sl No.",
                    "Provider Name",
                    "Status",
                    "Accept",
                    "Reject",
                    "Download",
                  ]}
                >
                  {v?.status_details?.map((val, i) => (
                    <Tr key={i}>
                      <Td>
                        <Center>{i + 1}</Center>
                      </Td>
                      <Td>
                        <Center>{val?.provider}</Center>
                      </Td>
                      <Td>
                        <Center>{val?.title}</Center>
                      </Td>
                      <Td>
                        <Center>
                          {val?.status == 0 ? (
                            <CustomButton
                              hover_req={false}
                              w="full"
                              title={"Accept"}
                              bg={"brand.primary"}
                              onClick={() => {
                                onOpen();
                                setData({
                                  ...data,
                                  id: val?.qt_id,
                                  mem_id: "",
                                  type: "Accept",
                                  status: 1,
                                });
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </Center>
                      </Td>
                      <Td>
                        <Center>
                          {val?.status == 0 ? (
                            <CustomButton
                              hover_req={false}
                              w="full"
                              title={"Reject"}
                              bg={"brand.btn"}
                              onClick={() => {
                                onOpen();
                                setData({
                                  ...data,
                                  id: val?.qt_id,
                                  mem_id: "",
                                  type: "Reject",
                                  status: 2,
                                });
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </Center>
                      </Td>
                      <Td>
                        <Center>
                          {val?.url ? (
                            <CustomButton
                              hover_req={false}
                              w="full"
                              title={"Download"}
                              bg={"brand.primary"}
                              onClick={() => {
                                //
                                downloadFile(val?.url);
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </Center>
                      </Td>
                    </Tr>
                  ))}
                </Table1>
              )}
            </Box>
          ))
        )}
      </Container>

      <CustomPopup
        isOpen={isOpen}
        onClick={update_status}
        b_name={data?.type}
        onClose={() => {
          onClose();
          setData({});
        }}
        title={data?.type}
        mess={`Are you sure? You want to ${data?.type} this Service. .`}
      />
    </Container>
  );
};

export default ViewInsMem;
