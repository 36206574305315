import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import store from "../../../../../redux/store";
import { get_mechanic_notification_list } from "../../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import { CustomButton, Loader } from "../../../../../utils/LayoutUtils";
import StatusComp from "../../../../../components/StatusComp";
import { ListDataShow } from "../../../../../components/Compnent";
import { useLocation, useNavigate } from "react-router-dom";
import CustomPopup from "../../../../../components/CustomPopup";
import { toast } from "react-toastify";
import { downloadFile } from "../../../../../utils/Hook/DownloadFile";
import { FeedBackbar } from "../../../../../components/FeedBack";
import { BorderFunction } from "../../../../../utils/utils";

const MechNotSup = () => {
  const [loading, setLoading] = useState(false);

  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const obj = { userid: user?.id };
  const [updateType, setUpdateType] = useState("");
  const [id, setId] = useState("");
  const { state } = useLocation();

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    supplier_mechanic_notification_list,
    supplier_mechanic_notification_loading,
  } = useSelector((state) => state.allData);
  console.log(
    "supplier_mechanic_notification_list ===",
    supplier_mechanic_notification_list
  );

  useEffect(() => {
    store.dispatch(get_mechanic_notification_list(obj));
  }, []);

  const status_update = async () => {
    // supplierRequest(id,user_id,status,mobile_number)
    onClose();
    try {
      setLoading(true);
      const body = new FormData();
      if (updateType == "Complete") {
        body.append("action", "supplier_complete_status");
        body.append("supplier_id", user?.id);
        body.append("id", id);
        body.append("complete", 1);
      } else {
        body.append("action", "supplierRequest");
        body.append(
          "status",
          updateType == "Accept" ? "1" : updateType == "Reject" ? "2" : ""
        );
        body.append("user_id", user?.id);
        body.append("id", id);
      }
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Mechanic.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        store.dispatch(get_mechanic_notification_list(obj));
        onClose();
        toast.success(res?.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error =====", error);
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Notifications"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Our Offering", to: "/home" },
          { name: "Mechanic/MI", to: -1 },
        ]}
      />

      <Container maxW={"container.md"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {supplier_mechanic_notification_loading || loading ? (
          <Loader />
        ) : supplier_mechanic_notification_list?.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          supplier_mechanic_notification_list?.map((v, i) => (
            <Box
              key={i}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "column"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              border={
           
            BorderFunction(v?.Status)
            }
              gap={[5, "5%"]}
              position={"relative"}
            >
              <StatusComp status={v?.Status} />
              <Flex flexDirection={"column"} w={"100%"}>
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Member Name"}
                  value={v?.Agency_name}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Address"}
                  value={v?.Address}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"From Date"}
                  value={v?.M_From_date}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"To Date"}
                  value={v?.To_date}
                />
                {v?.status == "1" && (
                  <>
                    <ListDataShow
                      f1={[4, 6]}
                      f2={[7, 14]}
                        title={"Email"}
                      value={v?.Email_Address}
                    />
                  </>
                )}
              </Flex>
              {v?.Rating && (
                  <>
                    <ListDataShow
                      f1={[4, 6]}
                      f2={[7, 14]}
                      title={"Rating"}
                      value={<FeedBackbar rating={v?.Rating} />}
                    />
                    <ListDataShow
                      f1={[4, 6]}
                      f2={[7, 14]}
                      title={"Feed-Back"}
                      value={v?.Feedback}
                    />
                  </>
                )}
              <Flex
                mt={2}
                w={["full", v?.Status == "0" ? "70%" : "50%"]}
                alignItems={"flex-end"}
                flexDirection={["row", "row"]}
                justifyContent={"flex-end"}
                gap={"3"}
              >
                {v?.Status == "0" ? (
                  <>
                    <CustomButton
                      w="full"
                      title={"Accept"}
                      bg={"brand.primary"}
                      onClick={() => {
                        setId(v?.ID);
                        setUpdateType("Accept");
                        onOpen();
                      }}
                    />
                    <CustomButton
                      w="full"
                      title={"Reject"}
                      bg={"brand.btn"}
                      onClick={() => {
                        setId(v?.ID);
                        setUpdateType("Reject");
                        onOpen();
                      }}
                    />
                  </>
                ) : v?.Status == "1" && v?.Complete_status == 0 ? (
                  <CustomButton
                    w="full"
                    title={"Complete"}
                    bg={"brand.primary"}
                    onClick={() => {
                      setId(v?.ID);
                      setUpdateType("Complete");
                      onOpen();
                    }}
                  />
                ) : (
                  //   <CustomButton
                  //     w="full"
                  //     title={"Feed Back"}
                  //     bg={"brand.primary"}
                  //     onClick={() => {
                  //       setId(v?.ID);
                  //       setUpdateType("Complete");
                  //       onOpen();
                  //     }}
                  //   />
                  ""
                )}

                <CustomButton
                  w="full"
                  title={"View/Download"}
                  bg={"black"}
                  onClick={() => downloadFile(v?.Document)}
                />
              </Flex>
            </Box>
          ))
        )}
      </Container>
      <CustomPopup
        isOpen={isOpen}
        onClick={status_update}
        b_name={updateType}
        onClose={onClose}
        title={updateType}
        mess={`Are you sure? You want to ${updateType} this Service.`}
      />
    </Container>
  );
};

export default MechNotSup;
