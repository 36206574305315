import { Container, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import ShowData from "../../../components/ShowData";
import { CustomButton } from "../../../utils/LayoutUtils";

const SupplierProfile = () => {
  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex gap={5} alignItems={"center"} justifyContent={"space-between"}>
        <Text fontSize={18} color={"#2b3478"} fontWeight={600}>
          Supplier
        </Text>
        <CustomButton title={"Edit"} />
      </Flex>
      <Container maxW={"container.lg"} py={5}>
        <Stack>
          <Flex
            gap={5}
            mt={2}
            flexDirection={["column", "column", "row", "row"]}
          >
            <ShowData title={"Name"} value={"Shyam Dattha"} flex={1} />
            <ShowData title={"Mobile Number"} value={"8885699244"} flex={1} />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData
              title={"Email ID"}
              value={"shyamdattha@gmail.com"}
              flex={1}
            />
            <ShowData
              title={"Services"}
              value={"Advisory,Consumable"}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"Address"} value={"Allapur,Boraband"} flex={1} />
            <ShowData title={"District"} value={"Nalgonda"} flex={1} />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"State"} value={"Telangana"} flex={1} />
            <Flex flex={1} />
          </Flex>
        </Stack>
      </Container>
    </Container>
  );
};

export default SupplierProfile;
