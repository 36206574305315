import SLIDER_1ST_IMG from "../assets/SliderImg/SLIDER_1ST_IMG.jpg";
import SLIDER_2ND_IMG from "../assets/SliderImg/SLIDER_2ND_IMG.jpg";
import SLIDER_3RD_IMG from "../assets/SliderImg/SLIDER_3RD_IMG.jpg";
import SLIDER_4TH_IMG from "../assets/SliderImg/SLIDER_4TH_IMG.jpg";

import JaganSir from "../assets/ourTeam/jaganSir.jpeg";
import Bangaru from "../assets/ourTeam/bangaru.jpeg";
import javvadi from "../assets/ourTeam/dc.jpeg";

import FinancialAdvisor from "../assets/Initiatives/FinancialAdvisor.jpeg";
import InsuranceServices from "../assets/Initiatives/InsuranceServices.jpeg";
import LegalAssistance from "../assets/Initiatives/LegalAssistance.jpeg";

import ourOfferingImg from '../assets/Supliers/OurOfferingIcon.svg'
import OrdersImg from '../assets/Supliers/OrdersIcon.svg'
import PaymentsImg from '../assets/Supliers/PaymentIcon.svg'

export const used_images = {
  slider_img: [
    {
      img: SLIDER_1ST_IMG,
    },
    {
      img: SLIDER_2ND_IMG,
    },
    {
      img: SLIDER_3RD_IMG,
    },
    {
      img: SLIDER_4TH_IMG,
    },
  ],
};

export const ourTeamData = [
  {
    img: JaganSir,
    name: "Mr. Kalluri Jagan Mohan Reddy",
    deg: "President",
    desc: "With a robust background spanning over 20 years as a seasoned businessman and an additional 16 year",
  },
  {
    img: Bangaru,
    name: "Ms. Bangaru Sruthi",
    deg: "Vice President",
    desc: "Ms. Bangaru Sruthi, our Vice President, boasts an impressive 25 years of experience in the LPG industry. Hailing from a reputed political family of national prominence, her extensive industry expertise and political acumen contribute to the strategic vis",
  },
  {
    img: javvadi,
    name: "Mr. Juvvadi Sricharn",
    deg: "General Secretary    ",
    desc: "Mr. Juvvadi Sricharn, our General Secretary, brings to the table 25 years of business experience, with a remarkable 13 years dedicated to the LPG industry. Highly networked in society, he plays a pivotal role in fostering connections that benefit our memb",
  },
];

export const InitiativesData = [
  {
    img: LegalAssistance,
    title: "Legal Assistance",
  },
  {
    img: InsuranceServices,
    title: "Insurance Services",
  },
  {
    img: FinancialAdvisor,
    title: "Financial Advisor",
  },
];

export const TermsData = [
  {
    Heading: "Eligibility",
    des: "Applicants must meet the specified qualifications and experience requirements for the respective roles of manager, mechanic, delivery personnel, or distributor as outlined in the job descriptions",
  },
  {
    Heading: "Application Process",
    des: "All applications must be submitted through the designated channels and include accurate and complete information. Any falsification of credentials will result in disqualification from consideration",
  },
  {
    Heading: "Selection Process",
    des: "Candidates will undergo a rigorous selection process, which may include interviews, assessments, and background checks, to determine suitability for the position.",
  },
  {
    Heading: "Employment Status",
    des: "Successful candidates will be offered employment on either a full-time or part-time basis, as per the requirements of the role and the discretion of the organization.",
  },
  {
    Heading: "Probationary Period",
    des: "Newly hired employees may be subject to a probationary period during which their performance and suitability for the role will be assessed",
  },
  {
    Heading: "Confidentiality",
    des: "Employees must maintain strict confidentiality regarding all sensitive information, including client data, internal processes, and proprietary business information.",
  },
  {
    Heading: "Code of Conduct",
    des: " Employees are expected to adhere to the organization's code of conduct, which promotes professionalism, integrity, respect, and ethical behavior in all interactions and transactions",
  },
  {
    Heading: "Training and Development",
    des: "Employees may be required to participate in training and development programs to enhance their skills and capabilities in alignment with organizational goals.",
  },
  {
    Heading: "Performance Evaluation",
    des: "Performance will be evaluated regularly, and employees may be provided with feedback and opportunities for growth and advancement within the organization based on their performance and contributions.",
  },
  {
    Heading: "Termination",
    des: "Employment may be terminated for reasons including but not limited to misconduct, performance issues, violation of company policies, or changes in business requirements.",
  },
  {
    Heading: "Legal Compliance",
    des: "All employees must comply with relevant laws, regulations, and industry standards governing their roles and responsibilities.",
  },
  {
    Heading: "Modification of Terms",
    des: "The organization reserves the right to modify or update these terms and conditions at any time, with or without prior notice, as deemed necessary for the effective operation of the business.    ",
  },
];

export const SupliersHome = [
  {
    id:1,
    img:ourOfferingImg,
    lable:'Our Offerings',
    path:"/home",
  },
  {
    id:2,
    img:OrdersImg,
    lable:'Orders',
    path:"/orders",
  },
  {
    id:3,
    img:PaymentsImg,
    lable:'Payment',
    path:"/payments",
  },
  {
    id:4,
    img:PaymentsImg,
    lable:'Profile',
    path:"/profile",
  },
]