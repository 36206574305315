import { Box, Container, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Loader } from "../../../../utils/LayoutUtils";

const AssociationDetails = () => {
  const { state } = useLocation();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const get_data = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "list");
      body.append("id", state.id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Association_info.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setList(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    get_data();
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={state.name}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Association Info", to: "/association-info" },
        ]}
      />
      <Container maxW={"container.lg"} p={4}>
        {loading ? (
          <Loader />
        ) : (
          list.map((item, index) => (
            <Flex
              key={index}
              shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
              p={[4, 5]}
              borderRadius={10}
              mt={5}
              gap={[2, 5]}
              bg={"#fff"}
            >
              <Box h={20} w={"full"} maxW={["60px", 20]}>
                <Image
                  src={item.Photo}
                  minW={["60px", "100%"]}
                  h={["60px", "100%"]}
                  w={["60px", "100%"]}
                  objectFit={"cover"}
                  borderRadius={"50%"}
                  alignSelf={"flex-start"}
                />
              </Box>

              <Stack spacing={0}>
                <Text fontSize={[17, 20]} color={"brand.text"} fontWeight={600}>
                  {item.Title}
                </Text>
                <Text fontSize={[13, 14]} color={"#2b3478"} fontWeight={400}>
                  {item.Role}
                </Text>
                <Text
                  fontSize={[13, 14]}
                  color={"brand.black"}
                  fontWeight={400}
                >
                  {item.Description}
                </Text>
                {/*
                 */}
              </Stack>
            </Flex>
          ))
        )}
      </Container>
    </Container>
  );
};

export default AssociationDetails;
