import { Box, Center, Container, Flex, Text } from "@chakra-ui/react";
import React from "react";
import BreadCrumName from "../../components/BreadCrumName";
import { CustomButton } from "../../utils/LayoutUtils";

const Payments = () => {
  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Payments"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.md"} p={4}>
        <Box p={5} bg={"#fff"} borderRadius={10}>
          <Flex gap={5} justifyContent={"space-between"}>
            <Text fontSize={[14,16,18]} color={"#797C7B"} fontWeight={"600"}>
              Client Name
            </Text>
            <Text fontSize={[14,16,18]} color={"#797C7B"} fontWeight={"600"}>
              Jagan Mohan Reddy
            </Text>
          </Flex>
          <Flex gap={5} justifyContent={"space-between"} my={2}>
            <Text fontSize={[14,16,18]} color={"#797C7B"} fontWeight={"600"}>
              Basic Plan
            </Text>
            <Text fontSize={[14,16,18]} color={"#797C7B"} fontWeight={"600"}>
              15000
            </Text>
          </Flex>
          <Flex gap={5} justifyContent={"space-between"}>
            <Text fontSize={[14,16,18]} color={"#797C7B"} fontWeight={"600"}>
              Date
            </Text>
            <Text fontSize={[14,16,18]} color={"#797C7B"} fontWeight={"600"}>
              2024-03-02
            </Text>
          </Flex>
        </Box>
        <Center mt={6}>
          <CustomButton title={"Pay"} />
        </Center>
      </Container>
    </Container>
  );
};

export default Payments;
