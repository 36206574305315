import { Stack, Text } from "@chakra-ui/react";
import React from "react";

const ShowData = ({ title, value, mt, flex }) => {
  return (
    <Stack borderBottom={"1px solid #0455BF"} pb={2} mt={mt} flex={flex}>
      <Text fontSize={14} color={"brand.black"} fontWeight={400}>
        {title}
      </Text>
      <Text fontSize={16} color={"brand.text"} fontWeight={400}>
        {value}
      </Text>
    </Stack>
  );
};

export default ShowData;
