import React from "react";
// import styles from "./homeslider.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Image } from "@chakra-ui/react";

const HomeSlider = ({ data }) => {
  return (
    <Swiper
      className="mySwiper"
      // height={"1000px"}
      loop={true}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
    >
      {data?.map((v, i) => (
        <SwiperSlide key={i}>
          <Image
            src={v?.Images}
            h={"100%"}
            width={"100%"}
            objectFit={"cover"}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HomeSlider;
