import React, { useState } from "react";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { FaHeart, FaIndianRupeeSign } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import img from "../assets/Supliers/OurOfferingIcon.svg";

export const CartCard = () => {
  const [heart, setHeart] = useState(true);
  const [count, setCount] = useState(1);
  return (
    <div class="item">
      <div class="buttons">
        <RxCross2 size={20} />
        <FaHeart
          onClick={() => setHeart(!heart)}
          cursor="pointer"
          color={heart ? "red" : "gray"}
          size={20}
        />
      </div>

      <div class="image">
        <img src={img} alt="" />
      </div>

      <div class="description">
        <span>Common Projects</span>
        <span>Bball High</span>
        <span>White</span>
      </div>

      <div class="quantity">
        <button class="plus-btn" type="button" name="button">
          <CiSquareMinus
            onClick={() => {
              setCount(count - 1);
            }}
            size={24}
          />
        </button>
        <input type="text" name="name" value={count} />
        <button class="minus-btn" type="button" name="button">
          <CiSquarePlus
            onClick={() => {
              setCount(count + 1);
            }}
            size={24}
          />
        </button>
      </div>

      <div class="total-price">
        <FaIndianRupeeSign />
        549
      </div>
    </div>
  );
};
