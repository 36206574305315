import { Container, Flex, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import img from "../assets/Supliers/letsShopImg.svg";
import { CustomButton } from "../utils/LayoutUtils";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { motion } from "framer-motion";
import { FaHeart } from "react-icons/fa6";
const MotionCiSquareMinus = motion(CiSquareMinus);
const MotionCiSquarePlus = motion(CiSquarePlus);

export const ProductCard = ({
  min_order,
  Discount,
  product_cat,
  onClick,
  EditIcon,
  DeleteIcon,
  url,
  price,
  qunatity,
  prod_name,
  subCat,
  add = false,
  count,
  setCount,
  heart,
  setHeart,
}) => {
  return (
    <GridItem
      maxW={400}
      w="100%"
      h={"100%"}
      bg="#fff"
      shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
      borderRadius={10}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      display={"flex"}
      flexDirection={"column"}
      cursor={"pointer"}
      p={3}
      gap={3}
      position={"relative"}
    >
      <Text
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          height: "40px",
          width: "40px",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
        }}
      >
        <FaHeart
          onClick={() => setHeart(!heart)}
          cursor="pointer"
          color={heart ? "red" : "gray"}
          size={20}
        />
      </Text>
      <Image
        src={url}
        h={["120px", "250px"]}
        w={["200px", "300px"]}
        objectFit={"fill"}
        border={"1px solid #2b3478"}
      />
      <Flex w={"100%"} justifyContent={"space-evenly"}>
        <Flex
          pl={3}
          w={"100%"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          flexDirection={"column"}
        >
          <Text fontSize={13}>{product_cat}</Text>
          <Text fontSize={13}>{subCat}</Text>
          <Text fontSize={13}>Rs. {Discount} Discount</Text>
          <Text fontSize={13}>Min Order Qty = </Text>
          {EditIcon && (
            <Text
              pl={4}
              color={"brand.primary"}
              fontSize={22}
              mt={3}
              textAlign={"center"}
            >
              {EditIcon}
            </Text>
          )}
        </Flex>
        <Flex
          pr={4}
          w={"100%"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
          flexDirection={"column"}
        >
          <Text fontSize={13}>{prod_name}</Text>
          <Text fontSize={13}>Rs. {Number(price).toFixed(2)}</Text>
          <Text fontSize={13}>Qty: {qunatity}</Text>
          <Text fontSize={13}>{min_order} pieces</Text>
          {DeleteIcon && (
            <Text
              pr={4}
              color={"brand.btn"}
              fontSize={25}
              mt={3}
              textAlign={"center"}
            >
              {DeleteIcon}
            </Text>
          )}
        </Flex>
      </Flex>

      {add && (
        <>
          {count == "0" ? (
            <CustomButton
              h={35}
              onClick={() => setCount(1)}
              hover_req={false}
              w={"full"}
              title={"Add to Cart"}
              bg={"brand.btn"}
            />
          ) : (
            <CustomButton
              hover_req={false}
              w={"full"}
              h={35}
              bg={"brand.primary"}
              rightIcon={
                <CiSquarePlus
                  onClick={() => {
                    setCount(count + 1);
                  }}
                  style={{ marginRight: "10px" }}
                  size={32}
                />
              }
              icon={
                <CiSquareMinus
                  onClick={() => {
                    setCount(count - 1);
                  }}
                  style={{ marginLeft: "10px" }}
                  size={32}
                />
              }
              title={count}
            />
          )}
        </>
      )}
    </GridItem>
  );
};

export const ProductDetailsCard = ({
  itemType,
  quntity,
  discount,
  min_qty,
  total_price,
  totalDiscount,
  totalAmount,
  image,
  status = 1,
}) => {
  console.log("totalAmount ==", totalAmount);
  return (
    <Flex flexDirection={["column", "row"]}>
      <Flex h={["120px", "250px"]} w={["200px", "500px"]} position={"relative"}>
        <Image
          src={image}
          h={["120px", "250px"]}
          w={["200px", "250px"]}
          objectFit={"fill"}
        />
      </Flex>
      <Flex
        p={3}
        gap={2}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        flexDirection={"column"}
      >
        <Text fontSize={13}>{itemType}</Text>
        <Text fontSize={13}>Office Items</Text>
        <Text fontSize={13}>Pen stand</Text>
        <Text fontSize={13}>Qty: {quntity}</Text>
        <Text fontSize={13}>{discount} Discount</Text>
        <Text fontSize={13}>Min Order Qty = {min_qty} pieces</Text>
        <Text fontSize={13}></Text>
      </Flex>
      <Flex
        p={3}
        gap={2}
        w={"250px"}
        justifyContent={"flex-start"}
        alignItems={"flex-end"}
        flexDirection={"column"}
      >
        <Text fontSize={13}>{total_price}</Text>
        <Text fontSize={13}>Discount {totalDiscount}</Text>
        <TotalPrice amt={totalAmount} />
      </Flex>
    </Flex>
  );
};

export const TotalPrice = ({ amt }) => {
  return (
    <Text
      borderTop={"4px"}
      borderBottom={"4px"}
      width={"100%"}
      p={2}
      textAlign={"right"}
      fontWeight={"bold"}
      fontSize={18}
    >
      {amt}
    </Text>
  );
};
