import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomButton,
  Loader,
  SelectImage,
} from "../../../../../utils/LayoutUtils";
import { ListDataShow, Table1 } from "../../../../../components/Compnent";
import { TableAddProd } from "../../../../../components/TableAddProd";
import CustomModal from "../../../../../components/CustomModal";
import { get_insu_sup_send_list } from "../../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import store from "../../../../../redux/store";
import { TypeOfVehicle } from "../../../../../utils/utils";
import { downloadFile } from "../../../../../utils/Hook/DownloadFile";
import { toast } from "react-toastify";

const InsuranceMain = () => {
  const [list, setlist] = useState([1, 2, 2, 3]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const { state } = useLocation();

  const obj = {
    action:
      state?.sub_category_id == 19
        ? "listDistributer"
        : state?.sub_category_id == 20
        ? "listEmpInsurannce"
        : state?.sub_category_id == 21
        ? "listMotorInsurannce"
        : state?.sub_category_id == 22
        ? "otherInsurance"
        : "",
    category_id: state?.cat_id,
    sub_category_id: state?.sub_category_id,
  };

  const { insu_sup_send_list, insu_sup_send_loading } = useSelector(
    (state) => state.allData
  );

  console.log("insu_sup_send_list ===", insu_sup_send_list);

  useEffect(() => {
    store.dispatch(get_insu_sup_send_list(obj));
  }, []);

  console.log("state ====", state);

  const send_quotation = async () => {
    if (!data?.file?.name) {
      setData({ ...data, fileMess: "Please Select a file" });
      return;
    }

    // createQuotation(category_id,sub_category_id,user_id,supplier_id,insurance_id,image)

    try {
      setLoading(true);
      const body = new FormData();

      body.append("action", "createQuotation");
      body.append("category_id", state?.cat_id);
      body.append("sub_category_id", state?.sub_category_id);
      body.append("supplier_id", user?.id);
      body.append("user_id", data?.mem_id);
      body.append("insurance_id", data?.ins_id);
      body.append("document", data?.file);
      body.append("ins_type", data?.ins_type);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Insurance.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        setData({});
        store.dispatch(get_insu_sup_send_list(obj));
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Insurance Request"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Our Offering", to: "/home" },
          { name: "Insurance", to: "/insurance" },
        ]}
      />
      <Container maxW={"container.md"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {loading || insu_sup_send_loading ? (
          <Loader />
        ) : insu_sup_send_list?.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          insu_sup_send_list?.map((v, i) => (
            <Box
              key={i}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "column"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={[5, "5%"]}
            >
              <Flex flexDirection={"column"} w={"100%"} gap={0}>
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Type of Request / Insurance"}
                  value={v?.Type_of_insurance}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Company"}
                  value={v?.company}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Name"}
                  value={v?.Name}
                />
                {v?.PName && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Proprietory Name"}
                    value={v?.PName}
                  />
                )}
                {v?.Address && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Address"}
                    value={v?.Address}
                  />
                )}
                {v?.Location && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Location"}
                    value={v?.Location}
                  />
                )}
                {v?.DOB && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Date of Birth"}
                    value={v?.DOB}
                  />
                )}
                {v?.Reg_num && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Vehicle Number"}
                    value={v?.Reg_num}
                  />
                )}

                {v?.Type_vehicle && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Type of Vehicle"}
                    value={
                      TypeOfVehicle?.filter(
                        (val, ind) => val?.value == v?.Type_vehicle
                      )?.[0]?.lable
                    }
                  />
                )}

                {v?.Model && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Make/Model"}
                    value={v?.Model}
                  />
                )}

                {v?.Mfg_year && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Mfg. year"}
                    value={v?.Mfg_year}
                  />
                )}
                {v?.son_wife_name && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Son/Wife"}
                    value={v?.son_wife_name}
                  />
                )}

                {(v?.Life_Cover || v?.Life_cover) && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Life Cover"}
                    value={v?.Life_Cover || v?.Life_cover}
                  />
                )}

                {(v?.Coverage_Till || v?.Coverage) && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Coverage Till Date"}
                    value={v?.Coverage_Till || v?.Coverage}
                  />
                )}

                {v?.Gender && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Gender"}
                    value={v?.Gender}
                  />
                )}

                {v?.Age && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Your Age"}
                    value={v?.Age}
                  />
                )}

                {v?.Gender && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Gender"}
                    value={v?.Gender}
                  />
                )}

                {/* <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Age"}
                  value={"25"}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"State"}
                  value={"Madhya Pradesh"}
                /> */}
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Description"}
                  value={v?.Description}
                />
              </Flex>
              <Flex
                mt={2}
                w={["full", "50%"]}
                alignItems={"flex-end"}
                flexDirection={["row", "row"]}
                justifyContent={"flex-end"}
                gap={"2"}
              >
                <CustomButton
                  w="full"
                  title={"Send Quotation"}
                  bg={"brand.btn"}
                  onClick={() => {
                    setData({
                      mem_id: v?.User_id,
                      ins_id: v?.ID,
                      ins_type: v?.Type_of_insurance,
                    });
                    onOpen();
                  }}
                />
                {v?.Document && (
                  <CustomButton
                    w="full"
                    title={"Download"}
                    bg={"brand.primary"}
                    onClick={() => downloadFile(v?.Document)}
                  />
                )}
              </Flex>
            </Box>
          ))
        )}
      </Container>
      <CustomModal
        onCancel={() => {
          setData({});
        }}
        isOpen={isOpen}
        onClose={onClose}
        title={"Add Quotation"}
      >
        <Container maxW={"container.md"} p={2}>
          <Table1>
            <TableAddProd
              item={
                <SelectImage
                  doctype={".pdf,.xls,.xlsx"}
                  filename={data?.file?.name}
                  onChange={(e) => {
                    setData({ ...data, file: e.target.files[0], fileMess: "" });
                  }}
                  errorMess={data?.fileMess}
                />
              }
              lable={"Add Quotation"}
            />
          </Table1>
          <Center mt={5}>
            <CustomButton
              title={"Submit"}
              onClick={send_quotation}
              loading={loading}
            />
          </Center>
        </Container>
      </CustomModal>
    </Container>
  );
};

export default InsuranceMain;
