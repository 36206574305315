import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useLocation } from "react-router-dom";
import {
  CustomButton,
  InputBox,
  Loader,
  SelectBox,
  SelectImage,
} from "../../../../utils/LayoutUtils";
import { toast } from "react-toastify";
import { IoMdAdd } from "react-icons/io";
import CustomModal from "../../../../components/CustomModal";
import ImageModal from "../../../../components/ImageModal";

const AddLeaglAssistance = () => {
  const { state } = useLocation();
  const [url, setUrl] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [dataList, setDataList] = useState([]);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const onAddAdvice = async () => {
    if (!data.title || !data.desc || !data?.file?.name) {
      setData({
        ...data,
        titleMess: !data.title ? "Title is required" : "",
        descMess: !data.desc ? "Description is required" : "",
        fileMess: !data.file?.name ? "File is required" : "",
      });
      return;
    }
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "create");
      body.append("category_id", state.cat_id);
      body.append("sub_category_id", state.subCat_id);
      body.append("userid", user.id);
      body.append("title", data.title);
      body.append("description", data.desc);
      body.append("image", data.file);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setData({});
        get_list();
        onClose();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const get_list = async () => {
    try {
      setLoading1(true);
      const body = new FormData();
      body.append("action", "list");
      body.append("category_id", state.cat_id);
      body.append("sub_category_id", state.subCat_id);
      body.append("userid", user.id);

      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setDataList(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading1(false);
    }
  };
  useEffect(() => {
    get_list();
  }, []);
  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={state.name}
          titlelist={[
            { name: "Home", to: "/" },
            { name: "Get Legal assistance", to: "/get-legal-assistance" },
          ]}
        />
        <Flex w={["100%", "auto"]} justifyContent={"flex-end"}>
          <CustomButton
            icon={<IoMdAdd size={20} color="#fff" />}
            title={"Add"}
            onClick={onOpen}
          />
        </Flex>
      </Flex>
      <Container maxW={"container.sm"} p={4}>
        {loading1 ? (
          <Loader />
        ) : dataList.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          dataList.map((item, index) => (
            <Box
              key={index}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "row"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={[5, "5%"]}
            >
              <Flex flexDirection={"column"} w={"80%"}>
                <Text fontSize={16} color={"brand.text"} fontWeight={"600"}>
                  {item.Title}
                </Text>
                <Text fontSize={16} color={"brand.black"} fontWeight={"400"}>
                  {item.Description}
                </Text>
              </Flex>
              <Flex w={"10%"}>
                <CustomButton
                  title={"View"}
                  onClick={() => {
                    setUrl(item.Document);
                    onOpen1();
                  }}
                />
              </Flex>
            </Box>
          ))
        )}
      </Container>
      <CustomModal
        isOpen={isOpen}
        onCancel={() => setData({})}
        onClose={onClose}
        title={`Add ${state.name}`}
      >
        <Container maxW={"container.sm"} p={4}>
          <InputBox
            label={"Title"}
            placeholder={"Title"}
            value={data.title}
            onChange={(e) => {
              setData({ ...data, title: e.target.value, titleMess: "" });
            }}
            errorMess={data?.titleMess}
          />
          <InputBox
            area={true}
            label={"Description"}
            placeholder={"Description"}
            value={data.desc}
            onChange={(e) => {
              setData({ ...data, desc: e.target.value, descMess: "" });
            }}
            errorMess={data?.descMess}
          />
          <SelectImage
            label={"Upload"}
            filename={data?.file?.name}
            onChange={(e) => {
              setData({ ...data, file: e.target.files[0], fileMess: "" });
            }}
            errorMess={data?.fileMess}
          />
          <Center mt={5}>
            <CustomButton
              title={"Submit"}
              onClick={onAddAdvice}
              loading={loading}
            />
          </Center>
        </Container>
      </CustomModal>
      <ImageModal url={url} isOpen={isOpen1} onClose={onClose1} />
    </Container>
  );
};

export default AddLeaglAssistance;
