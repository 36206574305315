import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../redux/store";
import BreadCrumName from "../../../../components/BreadCrumName";
import { get_messaging_list } from "../../../../redux/dataSlice";
import { Loader } from "../../../../utils/LayoutUtils";
import moment from "moment";
import { FaUserCircle } from "react-icons/fa";
import { LuSendHorizonal } from "react-icons/lu";

const Messaging = () => {
  const [message, setMessage] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const [userId, setUserId] = useState("");
  const [userDetail, setUserDetail] = useState({});
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const { messaging_list, messaging_loading } = useSelector(
    (state) => state.allData
  );
  const [search, setSearch] = useState("");
  const { win_width } = useSelector((state) => state.otherData);
  const navigate = useNavigate();
  const mobile = localStorage.getItem("mobile");
  useEffect(() => {
    store.dispatch(get_messaging_list());
  }, []);
  const get_chat = async (Id, item) => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("type", 2);
      body.append("SM", mobile);
      body.append("RM", Id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "messaging.php",
        {
          body: body,
          method: "POST",
        }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setMessages(res.data);
      } else {
        setMessages([]);
      }
    } catch (error) {}
  };
  const send_message = async (Id) => {
    if (message.length == 0) {
      return;
    }
    try {
      const body = new FormData();
      body.append("type", 3);
      body.append("SM", mobile);
      body.append("RM", userId);
      body.append("Message", message);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "messaging.php",
        {
          body: body,
          method: "POST",
        }
      );
      const res = await responce.json();

      if (res.success) {
        setMessage("");
        get_chat(userId);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const boxRef = useRef(null);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  }, [messages]);
  return (
    <Container maxW={"container.xl"} py={5} overflow={"hidden"} h={"100%"}>
      <BreadCrumName
        current={"Messaging"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Flex h={"calc( 100% - 40px )"} position={"relative"} gap={5} mt={3}>
        <Stack
          bg={"#fff"}
          px={5}
          maxW={["100%", win_width < 600 ? "100%":"300", "400"]}
          w={"100%"}
          flex={1}
          overflowY={"auto"}
          position={"relative"}
          pb={5}
        >
          <Flex position={"sticky"} top={0} bg={"#fff"} py={2}>
            <Input
              placeholder="Search..."
              bg={"#fff"}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Flex>
          {messaging_list
            ?.filter(
              (item) =>
                item.Agency_name?.toUpperCase().includes(
                  search?.toUpperCase()
                ) || item.Mobile_Number?.includes(search)
            )
            ?.map((item, index) => (
              <Box
                key={index}
                cursor={"pointer"}
                borderWidth={1}
                borderStyle={"solid"}
                borderColor={"brand.text1"}
                px={3}
                py={2}
                borderRadius={10}
                onClick={() => {
                  if (win_width < 600) {
                    navigate("/messaging_mob", {
                      state: {
                        Id: item.Mobile_Number,
                        mobile: mobile,
                        userDetail: item,
                      },
                    });
                  } else {
                    setUserId(item.Mobile_Number);
                    setUserDetail(item);
                    setMessages([]);
                    get_chat(item.Mobile_Number, item);
                  }
                }}
              >
                <Text fontSize={16} color={"#2b3478"} fontWeight={600}>
                  {item.Agency_name}
                </Text>
                <Text
                  fontSize={14}
                  mt={0}
                  color={"brand.text"}
                  fontWeight={400}
                >
                  {item.Mobile_Number}
                </Text>
              </Box>
            ))}
        </Stack>

        <Stack
          bg={"#fff"}
          justifyContent={"space-between"}
          flex={1}
          display={win_width < 600 ? "none" : "flex"}
        >
          {userId.length != 0 && (
            <>
              {loading && messages.length == 0 ? (
                <>
                  <Spacer />
                  <Loader />
                </>
              ) : (
                <>
                  {/* <Spacer /> */}
                  <Flex
                    bg={"#2b3478"}
                    py={2}
                    px={3}
                    alignItems={"center"}
                    gap={3}
                  >
                    <FaUserCircle size={40} color="#fff" />
                    <Stack spacing={0}>
                      <Text
                        fontSize={16}
                        color={"brand.white"}
                        fontWeight={600}
                      >
                        {userDetail.Agency_name}
                      </Text>
                      <Text
                        fontSize={14}
                        mt={0}
                        color={"brand.white"}
                        fontWeight={400}
                      >
                        {userDetail.Mobile_Number}
                      </Text>
                    </Stack>
                  </Flex>
                  <Box
                    ref={boxRef}
                    flexGrow={1}
                    overflowY="auto"
                    gap={2}
                    px={3}
                    sx={{
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      "-ms-overflow-style": "none",
                      "scrollbar-width": "none",
                    }}
                  >
                    {messages.map((item, index) => (
                      <Flex
                        key={index}
                        mt={1}
                        justifyContent={
                          item.SM == mobile ? "flex-end" : "flex-start"
                        }
                      >
                        <Flex
                          bg={"#F2F7FB"}
                          py={2}
                          px={4}
                          borderRadius={5}
                          alignSelf={"start"}
                          flexDirection={"column"}
                        >
                          <Text fontSize={16}>{item.Message}</Text>
                          <Text fontSize={12} textAlign={"end"}>
                            {moment(item.Datetime).format("LT")}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Box>
                </>
              )}
              <Box py={2} px={3}>
                <Flex spacing={2} gap={5}>
                  <Input
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    placeholder="Type your message here"
                  />
                  {/* <Button
                    colorScheme="blue"
                    variant="solid"
                    onClick={send_message}
                  >
                    Send
                  </Button> */}
                  <Flex alignItems={"center"} justifyContent={"center"}>
                    <LuSendHorizonal
                      size={25}
                      onClick={send_message}
                      color="#2b3478"
                      cursor={"pointer"}
                    />
                  </Flex>
                </Flex>
              </Box>
            </>
          )}
        </Stack>
      </Flex>
    </Container>
  );
};

export default Messaging;

// action = motor(category_id, sub_category_id, userid, typ_vehicle, reg_num, model, chasis, mfg_year, name, email_id, ida, image)


