import { Container, Flex, Grid, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../redux/store";
import { get_category } from "../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import ListItem from "../../../../components/ListItem";

const GetAdvice = () => {
  const { state } = useLocation();
  const { categoty_data, cat_loading } = useSelector((state) => state.allData);
  const { type } = useSelector((state) => state.otherData);
  const mobile_number = localStorage.getItem("mobile")
  const navigate = useNavigate();
  useEffect(() => {
    const body = new FormData();
    body.append("action", "Details");
    body.append("id", 4);
    body.append("Mobile_Number",mobile_number );
    store.dispatch(get_category(body));
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={"Get Advice"}
          titlelist={[{ name: "Home", to: "/" },
            type == 1&&{ name: "Our Offering", to: "/home" },
          ]}
        />
        {/* {type == 1 && (
          <Flex w={["100%", "auto"]} justifyContent={"flex-end"}>
            <CustomButton title={"Requested Services"} onClick={()=>navigate('/get-advice/requested-services')} />
          </Flex>
        )} */}
      </Flex>
      <Container maxW={"container.xl"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {cat_loading ? (
          <Loader />
        ) : categoty_data?.length == 0 ? (
          <Text>Working</Text>
        ) : (
          <Grid
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={[5, 5, 10]}
          >
            {categoty_data?.map((item, index) => (
              <ListItem
                key={index}
                title={item.name}
                url={item.Web_Images}
                onClick={() => {
                  navigate(`/get-advice/${item.name}`, {
                    state: { cat_id: 4, subCat_id: item.id, name: item.name },
                  });
                }}
              />
            ))}
          </Grid>
        )}
      </Container>
    </Container>
  );
};

export default GetAdvice;
