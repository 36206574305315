import { Center, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { CustomButton } from "../../utils/LayoutUtils";
import {
  HeadingMain,
  HeadingText,
  DotHeading,
} from "../../components/Compnent";

const Hiring = ({ Ref, data }) => {
  return (
    <>
      <HeadingMain title={"Our Hiring"} ref={Ref} />
      <Flex display={"flex"} gap={5}>
        <Stack flex={6} flexGrow={6} spacing={0}>
          {data?.map((v, i) => (
            <React.Fragment key={i}>
              <DotHeading title={v?.Title} />
              <HeadingText
                pl={["22px", "26px", "28px"]}
                title={v?.Description}
              />
              <Spacer h={2} />
            </React.Fragment>
          ))}
        </Stack>
      </Flex>
    </>
  );
};

export default Hiring;
