import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Center,
  Container,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ShowData from "../../../components/ShowData";
import store from "../../../redux/store";
import { get_profile } from "../../../redux/dataSlice";
import { useSelector } from "react-redux";
import {
  CustomButton,
  InputBox,
  Loader,
  SelectBox,
} from "../../../utils/LayoutUtils";
import CustomModal from "../../../components/CustomModal";
import { IoMdDownload } from "react-icons/io";
import { IoMdShare } from "react-icons/io";
import { certificate_download_api } from "../../../utils/apis";
import { toast } from "react-toastify";
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { ShareContent } from "../../../components/Compnent";
import { validateEmail2, validateMobile } from "../../../utils/utils";

const MemberProfile = ({ title }) => {
  const { profile_data, profile_loading, qr_image } = useSelector(
    (state) => state.allData
  );
  const cancelRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const [url, seturl] = useState("");
  const mobile = localStorage.getItem("mobile");
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  const [editItem, setEditItem] = useState({});
  const [loading, setloading] = useState(false);

  console.log("editItem ===", editItem);

  useEffect(() => {
    fetchData();
  }, [mobile]);
  const fetchData = () => {
    const body = new FormData();
    body.append("Mobile_Number", mobile);
    body.append("type", 1);
    store.dispatch(get_profile({body:body,data_type:1}));
  };

  const data = profile_data?.length == 0 ? {} : profile_data[0];

  const { win_width } = useSelector((state) => state.otherData);

  const Download_file = async (type) => {
    try {
      const body = new FormData();
      body.append("ID", user?.id);
      const res = await certificate_download_api(body);
      if (res?.success) {
        if (type == "download") {
          fetch(res.url)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.download = "image.png"; // Specify the desired filename with an extension
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch((error) =>
              console.error("Error downloading the image:", error)
            );
        } else if (type == "share") {
          seturl(res.url);
        }
      } else if (res?.success) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const update_profile = async () => {
    if (
      !editItem?.Agency_name ||
      !editItem?.DOC ||
      !editItem?.Address ||
      (editItem?.Address && editItem?.Address?.length < 25) ||
      (editItem?.DOC && editItem?.DOC?.length != 10) ||
      !editItem?.OMC_Type ||
      !editItem?.Email_Address ||
      !validateEmail2(editItem?.Email_Address)
    ) {
      setEditItem({
        ...editItem,
        Agency_nameMess: !editItem?.Agency_name
          ? "Agency Name is required"
          : "",
        DOCMess: !editItem?.DOC
          ? "DOC Date is required"
          : editItem?.DOC?.length != 10
          ? "Please Enter Valid DOC Date"
          : "",
        emailMess: !editItem?.Email_Address
          ? "Email is required"
          : !validateEmail2(editItem?.Email_Address)
          ? "Email is invalid"
          : "",
        OMC_TypeMess: !editItem?.OMC_Type ? "OMC Type is required" : "",
        AddressMess: !editItem?.OMC_Type
          ? "OMC Type is required"
          : editItem?.Address?.length < 25
          ? "Adress must be greaterthen 25 digit"
          : "",
      });
      return;
    }
    try {
      setloading(true);
      const body = new FormData();
      // (type,id,Agency_name,DOC,OMC_Type,Address,Email_Address)
      body.append("agency_id", editItem?.Agency_ID);
      body.append("type", 5);
      body.append("Agency_name", editItem?.Agency_name);
      body.append("DOC", editItem?.DOC);
      body.append("Email_Address", editItem?.Email_Address);
      body.append("OMC_Type", editItem?.OMC_Type);
      body.append("Address", editItem?.Address);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "profile.php",
        {
          method: "POST",
          body: body,
        }
      );
      const res = await responce.json();
      setloading(false);

      if (res.success) {
        toast.success(res.message);
        setEditItem({});
        fetchData();
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Flex gap={5} alignItems={"center"} justifyContent={"space-between"}>
        <Text fontSize={18} color={"#2b3478"} fontWeight={600}>
          {title}
        </Text>
        <CustomButton
          title={"Edit"}
          onClick={() => {
            onOpen();
            setEditItem(data);
          }}
        />
      </Flex>
      {profile_loading ? (
        <Loader />
      ) : (
        <Stack>
          <Flex
            gap={5}
            mt={2}
            flexDirection={["column", "column", "row", "row"]}
          >
            <Flex
              display={["flex", "none", "none"]}
              justify={"center"}
              align={"center"}
            >
              {qr_image && (
                <Stack>
                  <img src={process.env.REACT_APP_BASE_URL + qr_image} alt="" />
                </Stack>
              )}
            </Flex>
            <ShowData title={"Agency ID"} value={data?.Agency_ID} flex={1} />
            <ShowData
              title={"Agency Name"}
              value={data?.Agency_name}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"DOC"} value={data?.DOC} flex={1} />
            <ShowData title={"OMC Type"} value={data?.OMC_Type} flex={1} />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData
              title={"Agency Consistution"}
              value={data?.Agency_Const}
              flex={1}
            />
            <ShowData
              title={"Agency Category"}
              value={data?.Agency_cate}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"Agency MKT"} value={data?.Agency_MKT} flex={1} />
            <ShowData
              title={"Agency Reconsitution"}
              value={data?.Agency_recon}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"Address"} value={data?.Address} flex={1} />
            <ShowData title={"District"} value={data?.district} flex={1} />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData
              title={"State"}
              value={data?.State || "Telangana"}
              flex={1}
            />
            <ShowData
              title={"Mobile Number"}
              value={data?.Mobile_Number}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData
              title={"Email Address"}
              value={data?.Email_Address}
              flex={1}
            />
            <ShowData
              title={"Date of Membership"}
              value={data?.Date_of_Membership}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData
              title={"Distributor Type"}
              value={data?.Distributor_Type}
              flex={1}
            />
            <ShowData title={"Date Time"} value={data?.DataTime} flex={1} />
          </Flex>

          {/* <Flex gap={5} alignItems={"center"} mt={5}>
            <Text>Registration Certificate</Text>
            <CustomButton
              onClick={() => Download_file("download")}
              title={
                win_width < 500 ? (
                  <IoMdDownload color="#fff" size={22} cursor={"pointer"} />
                ) : (
                  "Download"
                )
              }
            />
          </Flex> */}
        </Stack>
      )}

      <AlertDialog
        isOpen={isOpen1}
        leastDestructiveRef={cancelRef}
        onClose={onClose1}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Share App
            </AlertDialogHeader>
            <AlertDialogBody>
              <Center gap={5} mb={3}>
                <WhatsappShareButton
                  title={"My LPG Association"}
                  separator=":: "
                  url={url}
                >
                  <WhatsappIcon round={true} size={40} />
                </WhatsappShareButton>
                <TelegramShareButton
                  title={"My LPG Association"}
                  separator=":: "
                  url={url}
                >
                  <TelegramIcon round={true} size={40} />
                </TelegramShareButton>
                <EmailShareButton subject={"My LPG Association"} url={url}>
                  <EmailIcon round={true} size={40} />
                </EmailShareButton>
              </Center>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        title={"Update Member Profile"}
      >
        <Flex
          gap={[0, 0, 5]}
          flexDirection={["column", "column", "row", "row"]}
        >
          <InputBox
            value={editItem?.Agency_name}
            onChange={(v) => {
              setEditItem({
                ...editItem,
                Agency_name: v.target.value,
                Agency_nameMess: "",
              });
            }}
            errorMess={editItem?.Agency_nameMess}
            placeholder={"Agency Name"}
            label={"Agency Name"}
          />
          <InputBox
            value={editItem?.DOC}
            onChange={(v) => {
              setEditItem({ ...editItem, DOC: v.target.value, DOCMess: "" });
            }}
            errorMess={editItem?.DOCMess}
            type={"date"}
            placeholder={"DOC"}
            label={"DOC"}
          />
        </Flex>
        <Flex
          gap={[0, 0, 5]}
          flexDirection={["column", "column", "row", "row"]}
        >
          {/* <InputBox placeholder={"OMC Type"} label={"OMC Type"} /> */}
          <SelectBox
            label={"OMC Type"}
            placeholder={"OMC Type"}
            value={editItem.OMC_Type}
            onChange={(v) => {
              setEditItem({
                ...editItem,
                OMC_Type: v.target.value,
                OMC_TypeMess: "",
              });
            }}
            errorMess={editItem?.OMC_TypeMess}
            options={
              <>
                <option value="HPCL">HPCL</option>
                <option value="BPCL">BPCL</option>
                <option value="IOCL">IOCL</option>
              </>
            }
          />

          <InputBox
            value={editItem?.Address}
            onChange={(v) => {
              if (v?.target.value?.length <= 200) {
                setEditItem({
                  ...editItem,
                  Address: v.target.value,
                  AddressMess: "",
                });
              }
            }}
            errorMess={editItem?.AddressMess}
            placeholder={"Address"}
            label={"Address"}
          />
        </Flex>

        <Flex
          gap={[0, 0, 5]}
          flexDirection={["column", "column", "row", "row"]}
        >
          <InputBox
            value={editItem?.Email_Address}
            onChange={(v) => {
              setEditItem({
                ...editItem,
                Email_Address: v.target.value,
                Email_AddressMess: "",
              });
            }}
            errorMess={editItem?.Email_AddressMess}
            placeholder={"Email Address"}
            label={"Email Address"}
          />
          <Flex flex={1} />
        </Flex>
        <Center mt={5} mb={5}>
          <CustomButton
            title={"Update"}
            onClick={update_profile}
            loading={loading}
          />
        </Center>
      </CustomModal>
    </>
  );
};

export default MemberProfile;
