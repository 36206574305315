import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useSelector } from "react-redux";
import CustomPopup from "../../../../components/CustomPopup";
import { FaStar } from "react-icons/fa6";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import StatusComp from "../../../../components/StatusComp";
import { ListDataShow } from "../../../../components/Compnent";
import { get_mechanic_order_list } from "../../../../redux/dataSlice";
import store from "../../../../redux/store";
import { downloadFile } from "../../../../utils/Hook/DownloadFile";
import { FeedBack, FeedBackbar } from "../../../../components/FeedBack";
import { toast } from "react-toastify";
import { BorderFunction } from "../../../../utils/utils";

const MechOrderMem = () => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(null);
  const [Feedback, setFeedback] = useState("Move");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");

  const [data, setData] = useState({});

  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const obj = { userid: user?.id };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  useEffect(() => {
    store.dispatch(get_mechanic_order_list(obj));
  }, []);

  const { member_mech_order_list, member_mech_order_loading } = useSelector(
    (state) => state.allData
  );

  console.log("member_mech_order_list =====", member_mech_order_list);

  const update_feedback = async () => {
    if (data?.rating && data?.message && !data?.messError) {
      onClose1();
      try {
        // feedback(user_id,mechanic_id,feedback,rating)  // Member side feedback send to supplier
        setLoading(true);
        const body = new FormData();
        body.append("action", "feedback");
        body.append("user_id", user?.id);
        body.append("mechanic_id", id);
        body.append("feedback", data?.message);
        body.append("rating", data?.rating);
        const responce = await fetch(
          process.env.REACT_APP_BASE_URL + "Mechanic.php",
          { method: "POST", body: body }
        );
        const res = await responce.json();
        setLoading(false);
        if (res.success) {
          store.dispatch(get_mechanic_order_list(obj));
          setData({});
          setId("");
          toast.success(res?.message);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setData({
        ...data,
        ratingError: !data?.rating ? "Please Select Rating First" : "",
        messError: !data?.message ? "Please Enter Message" : "",
      });
    }
  };

  const status_update = async () => {
    // user_complete_status(id,user_id,complete)   // Member side api complete_status done

    onClose();
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "user_complete_status");
      body.append("user_id", user?.id);
      body.append("id", id);
      body.append("complete", 1);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Mechanic.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        store.dispatch(get_mechanic_order_list(obj));
        onClose();
        setData({});
        onOpen1();
        toast.success(res?.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error =====", error);
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Orders"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Mechanic/MI", to: -1 },
        ]}
      />

      <Container maxW={"container.md"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {member_mech_order_loading || loading ? (
          <Loader />
        ) : member_mech_order_list?.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          member_mech_order_list?.map((v, i) => (
            <Box
              key={i}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "column"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              border={BorderFunction(v?.Status)}
              gap={[5, "5%"]}
              position={"relative"}
            >
              <StatusComp status={v?.Status} />
              <Flex flexDirection={"column"} w={"100%"}>
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Supplier Name"}
                  value={v?.Name}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Address"}
                  value={v?.Address}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"From Date"}
                  value={v?.M_From_date}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"To Date"}
                  value={v?.To_date}
                />

                {v?.Rating && (
                  <>
                    <ListDataShow
                      f1={[4, 6]}
                      f2={[7, 14]}
                      title={"Rating"}
                      value={<FeedBackbar rating={v?.Rating} />}
                    />
                    <ListDataShow
                      f1={[4, 6]}
                      f2={[7, 14]}
                      title={"Feed-Back"}
                      value={v?.Feedback}
                    />
                  </>
                )}

                {/* {v?.status == "1" && (
                  <>
                    <ListDataShow
                      f1={[4, 6]}
                      f2={[7, 14]}
                      //   title={"Name"}
                      value={v?.Email_Address}
                    />
                  </>
                )} */}
              </Flex>
              <Flex
                mt={2}
                w={["full", v?.Status == "0" ? "70%" : "50%"]}
                alignItems={"flex-end"}
                flexDirection={["row", "row"]}
                justifyContent={"flex-end"}
                gap={"3"}
              >
                {v?.Complete_status == 0 ? (
                  <CustomButton
                    w="full"
                    title={"Complete"}
                    bg={"brand.primary"}
                    onClick={() => {
                      setId(v?.ID);
                      onOpen();
                    }}
                  />
                ) : v?.Rating > 0 ? (
                  ""
                ) : (
                  <CustomButton
                    w="full"
                    title={"Feed Back"}
                    bg={"brand.primary"}
                    onClick={() => {
                      setId(v?.ID);
                      onOpen1();
                    }}
                  />
                )}

                <CustomButton
                  w="full"
                  title={"View/Download"}
                  bg={"black"}
                  onClick={() => downloadFile(v?.Document)}
                />
              </Flex>
            </Box>
          ))
        )}
      </Container>

      <CustomPopup
        isOpen={isOpen}
        onClick={status_update}
        b_name={"Complete"}
        onClose={onClose}
        title={"Complete"}
        mess={`Are you sure? You want to Complete this Service.`}
      />
      <CustomPopup
        isOpen={isOpen1}
        onClick={update_feedback}
        b_name={"Submit"}
        onClose={() => {
          onClose1();
          setData({});
        }}
        title={"Feedback"}
        mess={<FeedBack data={data} setData={setData} />}
      />
    </Container>
  );
};

export default MechOrderMem;
