import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

const CustomModal = ({ isOpen, onCancel,onClose, title, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      size={"xl"}
      onClose={()=>{
        onClose();
        if(onCancel){
          onCancel()
        }
      }}
      scrollBehavior={"inside"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW={"container.lg"} w={"full"}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody mt={0} pt={0}>
          {children}
        </ModalBody>

        {/* <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost">Secondary Action</Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
